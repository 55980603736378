import React, {Component} from 'react';
import { Input, Form, Checkbox, Button, Icon, Segment } from 'semantic-ui-react'
import User from '../../models/User';
import Header from '../../assets/components/header';
import CenterLoader from '../../assets/components/CenterLoader';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import logo from '../../assets/images/mashie-shield-with-text.png';
import styles from './login.module.scss';

class ResetPassword extends Component {
	constructor(props) {
		super(props);

		this.state = {
			errors: [],
			reset: false,
			loading: false,
			password: '',
			userType: null,
		};
	}

	errorsFor(field) {
		if (typeof this.state.errors[field] !== 'undefined') {
			return <span className="has-error"> { this.state.errors[field][0] } </span>
		}
	}

	reset() {
		this.setState({loading: true});
		User.resetPassword(this.props.match.params.token, this.state.password)
			.then(response => {
				this.setState({ errors: [], reset: true, loading: false, userType: response.data.userType})
			}).catch(error => {
				this.setState({loading: false, reset: false, errors: error.response.data.errors});
			});
	}

	gatherInput(event) {
		this.setState({ [event.target.name]: event.target.value });
	}

	successForUserType() {
		if (this.state.userType === 'admin') {
			return (
				<>
					<div className="text-center"><Icon name="check circle" size="huge" color="green"/></div>
					<h2 className="text-center">YOUR PASSWORD HAS BEEN RESET</h2>
					<p>You can now log in using your new password</p>
					<Link to="/auth/login"><Button primary className="theme">Log in</Button></Link>
				</>
			);
		}

		return (
			<>
				<div className="text-center"><Icon name="check circle" size="huge" color="green"/></div>
				<h2 className="text-center">YOUR PASSWORD HAS BEEN RESET</h2>
				<p>You can now log in to the app using your new password</p>
			</>
		);
	}

	body() {
		if (this.state.reset === true) {
			return this.successForUserType();
		}

		return (
			<>
				<h2 className="text-center">RESET YOUR PASSWORD</h2>
				<p>Go ahead and set a new password. It must be at least eight characters long.</p>
				{ this.errorsFor('token') }
				<Form>
					<Form.Field>
						{ this.errorsFor('password') }
						<Input fluid type="password" placeholder="New password" size="huge" icon="shield alternate" iconPosition="left" name="password" onChange={this.gatherInput.bind(this)}/>
					</Form.Field>
					<Form.Field>
						<Button fluid primary size="huge" className="theme" loading={this.state.loading} onClick={this.reset.bind(this)}>
							<Icon name="check" />
						</Button>
					</Form.Field>
				</Form>
			</>
		);
	}

	render() {
		return (
			<>
				<div className="container-fluid px-0 full-height">
					<div className="row no-gutters full-height">
						<div className="d-none d-sm-block col-sm-6 col-md-8">
							<div className={styles.fullImage}>
							</div>
						</div>
						<div className="col-12 col-sm-6 col-md-4">
							<div className="light-background perfect-center">
								<div className={'content ' + styles.loginBox}>
									<img className={styles.logo} alt="" src={logo} />
									{ this.body() }
								</div>
							</div>
						</div>
					</div>
				</div>
			</>
		);
	}
}

function mapStateToProps(state) {
	return { user: state.user };
}


export default connect(mapStateToProps)(ResetPassword);
