import React from "react";

export default function ({ score }) {
    const styles = {
        fontSize: "30px",
    };

    const optionContent = (iteration) => {
        if (score >= iteration) {
            return "★";
        }

        return "☆";
    };

    return (
        <p style={styles}>
            {[1, 2, 3, 4, 5].map((option) => {
                return <span key={option}>{optionContent(option)}</span>;
            })}
            <span style={{ fontSize: "24px", marginLeft: "10px" }}>
                <strong>
                    {score}
                    /5
                </strong>
            </span>
        </p>
    );
}
