import React, { Component } from "react";
import { Segment, Form, TextArea, Button } from "semantic-ui-react";
import NotificationActions from "../../../store/actions/notification";
import { connect } from "react-redux";

class Description extends Component {
  gatherInput(property, value) {
    const { club } = this.props;
    club[property] = value;
    this.props.update(club);
  }

  async save() {
    await this.props.club.update();
    this.props.dispatch(
      NotificationActions.create({ type: "success", text: "Description saved" })
    );
  }

  render() {
    return (
      <>
        <Form>
          <p>Add a description for this club</p>
          <TextArea
            rows="10"
            name="description"
            value={this.props.club.description || ""}
            onChange={({ target }) =>
              this.gatherInput("description", target.value)
            }
            placeholder="Description..."
          />
          <p>Club notes</p>
          <TextArea
            rows="10"
            name="otherInformation"
            value={this.props.club.otherInformation || ""}
            onChange={({ target }) =>
              this.gatherInput("otherInformation", target.value)
            }
            placeholder="Other information..."
          />
        </Form>
        <div className="row">
          <div className="col">
            <Segment vertical>
              <Button
                size="large"
                floated="right"
                className="theme"
                primary
                onClick={this.save.bind(this)}
              >
                Save
              </Button>
            </Segment>
          </div>
        </div>
      </>
    );
  }
}

function mapStateToProps(state) {
  return {
    user: state.user,
    notification: state.notification,
  };
}

export default connect(mapStateToProps)(Description);
