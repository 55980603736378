class NotificationAction {
	static create(data) {
		return { type: "SET_NOTIFICATION", payload: data };
	}

	static remove(data) {
		return { type: "CLEAR_NOTIFICATION" };
	}
}

export default NotificationAction;
