import React, { useState } from "react";

export default function ({ defaultScore = 5, onChange }) {
    const [hoverScore, setHoverScore] = useState(null);
    const [selectedScore, setSelectedScore] = useState(defaultScore);

    const styles = {
        fontSize: "30px",
        cursor: "pointer",
    };

    const optionContent = (score) => {
        if (hoverScore !== null && hoverScore >= score) {
            return "★";
        }

        if (hoverScore !== null && hoverScore < score) {
            return "☆";
        }

        if (selectedScore >= score) {
            return "★";
        }

        return "☆";
    };

    const onSelectScore = (selected) => {
        setSelectedScore(selected);
        onChange(selected);
    };

    return (
        <p style={styles} onMouseLeave={() => setHoverScore(null)}>
            {[1, 2, 3, 4, 5].map((option) => {
                return (
                    <span
                        key={option}
                        onMouseOver={() => setHoverScore(option)}
                        onClick={() => onSelectScore(option)}
                    >
                        {optionContent(option)}
                    </span>
                );
            })}
            <span style={{ fontSize: "24px", marginLeft: "10px" }}>
                <strong>
                    {selectedScore}
                    /5
                </strong>
            </span>
        </p>
    );
}
