import React from "react";
import {
    PlaceholderParagraph,
    PlaceholderLine,
    Placeholder,
} from "semantic-ui-react";

export default ({ fluid = false }) => {
    return (
        <div className="mt-3 p-3">
            <Placeholder fluid={fluid}>
                <PlaceholderParagraph>
                    <PlaceholderLine />
                    <PlaceholderLine />
                    <PlaceholderLine />
                    <PlaceholderLine />
                    <PlaceholderLine />
                </PlaceholderParagraph>
                <PlaceholderParagraph>
                    <PlaceholderLine />
                    <PlaceholderLine />
                    <PlaceholderLine />
                </PlaceholderParagraph>
            </Placeholder>
        </div>
    );
};
