const axios = require('axios');
const config = require('../config');

class Piece {
	constructor(properties) {
		Object.assign(this, properties);
	}

	static loadFeatured() {
		return axios.get(config.api_url + 'discover/featured')
		.then(response => {
				return response;
			}).catch(error => {
				throw error;
			});
	}

	static loadCategory(category) {
		return axios.get(config.api_url + 'discover/' + category)
		.then(response => {
				return response;
			}).catch(error => {
				throw error;
			});
	}

	static loadPieces() {
		return axios.get(config.api_url + 'pieces')
		.then(response => {
				return response;
			}).catch(error => {
				throw error;
			});
	}

	static loadPieceRead(slug) {
		return axios.get(config.api_url + 'read/' + slug)
		.then(response => {
				return response;
			}).catch(error => {
				throw error;
			});
	}

	static loadPieceDraft(id) {
		return axios.get(config.api_url + 'draft/' + id)
		.then(response => {
				return response;
			}).catch(error => {
				throw error;
			});
	}

	static search(term) {
		return axios.get(config.api_url + 'search/' + term)
		.then(response => {
				return response;
			}).catch(error => {
				throw error;
			});
	}

	store() {
		return axios.post(config.api_url + 'pieces', {
			title: this.title,
			slug: this.slug,
			blurb: this.blurb,
			form: this.form,
			category: this.category,
			keywords: this.keywords,
			content: this.content,
			publshed: this.published
			}).then(response => {
				return response;
			}).catch(error => {
				throw error;
			});
	}

	update() {
		return axios.put(config.api_url + 'pieces/' + this._id, {
			title: this.title,
			slug: this.slug,
			blurb: this.blurb,
			form: this.form,
			category: this.category,
			keywords: this.keywords,
			content: this.content,
			published: this.published
			}).then(response => {
				return response;
			}).catch(error => {
				throw error;
			});
	}

	delete() {
		return axios.delete(config.api_url + 'pieces/' + this._id)
			.then(response => {
				return response;
			}).catch(error => {
				throw error;
			});
	}
}

export default Piece;
