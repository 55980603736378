import axios from "axios";
const config = require("../config");

class BookingReview {
    constructor(properties) {
        Object.assign(this, properties);
        if (typeof this.approvedAt === "string") {
            this.approvedAt = new Date(this.approvedAt);
        }

        if (typeof this.submittedAt === "string") {
            this.submittedAt = new Date(this.submittedAt);
        }

        if (typeof this.rejectedAt === "string") {
            this.rejectedAt = new Date(this.rejectedAt);
        }

        if (typeof this.expiredAt === "string") {
            this.expiredAt = new Date(this.expiredAt);
        }

        if (typeof this.paidAt === "string") {
            this.paidAt = new Date(this.paidAt);
        }

        if (typeof this.createdAt === "string") {
            this.createdAt = new Date(this.createdAt);
        }
    }

    static async index(params) {
        const { data } = await axios.get(config.api_url + "reviews/bookings", {
            params,
        });
        return {
            reviews: data.data.map((item) => new BookingReview(item)),
            total: data.total,
        };
    }

    static async show(id) {
        const { data } = await axios.get(
            config.api_url + "reviews/bookings/" + id
        );
        return new BookingReview(data);
    }

    approve() {
        return axios.post(
            config.api_url + "reviews/bookings/" + this._id + "/approve"
        );
    }

    reject(payload) {
        return axios.post(
            config.api_url + "reviews/bookings/" + this._id + "/reject",
            payload
        );
    }

    static async publicShow(reference) {
        const { data } = await axios.get(
            config.api_url + "public/reviews/bookings/" + reference
        );
        return new BookingReview(data);
    }

    publicSubmit(payload) {
        return axios.post(
            config.api_url +
                "public/reviews/bookings/" +
                this.reference +
                "/submit",
            payload
        );
    }
}

export default BookingReview;
