import React, { Component } from "react";
import { connect } from "react-redux";
import { Button, Checkbox, Segment } from "semantic-ui-react";
import EntitySearchSelect from "../EntitySearchSelect";
import Entity from "../../../models/Entity";

class Entities extends Component {
    errorsFor(field) {
        if (this.props.validation !== null) {
            if (typeof this.props.validation[field] !== "undefined") {
                return (
                    <span className="has-error">
                        {" "}
                        {this.props.validation[field][0]}{" "}
                    </span>
                );
            }
        }
    }

    onChangeEntityPrimary(index) {
        const { user } = this.props;
        const primary = !user.entities[index].primary;
        if (primary === true) {
            user.entities.forEach((entity) => {
                entity.primary = false;
            });
        }

        user.entities[index].primary = primary;
        this.props.update(user);
    }

    async onChangeNewEntity(value) {
        const exists = this.props.user.entities.some(
            ({ entity }) => entity._id === value
        );
        if (exists === true) return;
        const entities = await Entity.list({ include: value });
        this.props.user.entities.push({ entity: entities[0], primary: false });
        this.props.update(this.props.user);
    }

    removeEntity(index) {
        const { user } = this.props;
        user.entities.splice(index, 1);
        this.props.update(user);
    }

    addEntity() {
        return (
            <Segment vertical>
                <div className="row">
                    <div className="col-6">
                        <h3>Add new entity</h3>
                        <EntitySearchSelect
                            onChange={(value) => this.onChangeNewEntity(value)}
                        />
                    </div>
                </div>
            </Segment>
        );
    }

    listEntities() {
        return this.props.user.entities.map((entity, i) => {
            return (
                <div className="row mt-3" key={i}>
                    <div className="col-sm-12 col-md-9 mb-2">
                        <p>{entity.entity.name}</p>
                    </div>
                    <div className="col-xs-12 col-md-3 text-right d-flex align-items-center justify-content-end">
                        <Checkbox
                            toggle
                            label="Primary"
                            checked={entity.primary}
                            onChange={() => this.onChangeEntityPrimary(i)}
                        />
                        <Button
                            primary
                            className="red ml-3"
                            value={entity.entity._id}
                            onClick={() => this.removeEntity(i)}
                        >
                            Remove
                        </Button>
                    </div>
                </div>
            );
        });
    }

    render() {
        return (
            <>
                {this.errorsFor("entities")}
                {this.listEntities()}
                {this.addEntity()}
                <Segment vertical>
                    <Button
                        size="large"
                        floated="right"
                        className="theme"
                        primary
                        onClick={this.props.save}
                    >
                        Save
                    </Button>
                </Segment>
            </>
        );
    }
}

function mapStateToProps(state) {
    return {
        validation: state.validation,
    };
}

export default connect(mapStateToProps)(Entities);
