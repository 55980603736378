import axios from "axios";
import profileImagePlaceholder from "../assets/images/camera-placeholder.png";
const config = require("../config");

class User {
  constructor(properties) {
    this.emailAddresses = [];
    this.phoneNumbers = [];
    this.roles = [];
    this.permissions = [];
    this.allPermissions = [];
    this.addresses = [];
    Object.assign(this, properties);
  }

  static login(email, password) {
    return axios.post(config.api_url + "auth/login", {
      email: email,
      password: password,
    });
  }

  logout() {
    return axios.post(config.api_url + "auth/logout");
  }

  has(key) {
    if (typeof this.roles !== "undefined") {
      return this.allPermissions.some((permission) => permission.key === key);
    }

    return false;
  }

  hasRole(key) {
    if (typeof this.roles !== "undefined") {
      return this.roles.some((role) => role.role.key === key);
    }

    return false;
  }

  canAdministerUser(user) {
    if (this.has("MASHIE_ADMIN")) {
      return true;
    }

    if (this.has("LOGIN_ADMIN") === false) {
      return false;
    }

    // Check that this (the admin) has access to administer the primary company to which user (subject) belongs.
    if (
      String(this.currentAdministrationEntity._id) ===
      String(user.entities.find((entity) => entity.primary === true).entity._id)
    ) {
      return true;
    }

    return false;
  }

  static verfyEmail(token) {
    return axios
      .post(config.api_url + "auth/verify/" + token)
      .then((response) => {
        return response;
      })
      .catch((error) => {
        throw error;
      });
  }

  static requestPasswordReset(email) {
    return axios.post(config.api_url + "auth/reset-password", { email });
  }

  static resetPassword(token, password) {
    return axios.post(config.api_url + "auth/reset-password/" + token, {
      password,
    });
  }

  static register(
    firstName,
    surname,
    email,
    phone,
    password,
    registrationToken,
    terms,
    termsLink,
    privacyLink
  ) {
    return axios.post(config.api_url + "auth/register", {
      firstName,
      surname,
      email,
      phone,
      password,
      registrationToken,
      terms,
      termsLink,
      privacyLink,
    });
  }

  isAuthenticated() {
    return typeof this._id !== "undefined";
  }

  static authCheck() {
    return axios
      .get(config.api_url + "auth/check")
      .then((response) => {
        return response;
      })
      .catch((error) => {
        throw error;
      });
  }

  static getMashieAdmins() {
    return axios
      .get(config.api_url + "mashie-admins")
      .then((response) => {
        return response;
      })
      .catch((error) => {
        throw error;
      });
  }

  static getMashieAdminById(id) {
    return axios
      .get(config.api_url + "mashie-admins/" + id)
      .then((response) => {
        return response;
      })
      .catch((error) => {
        throw error;
      });
  }

  updateProfile() {
    return axios.put(config.api_url + "settings/profile", {
      firstName: this.firstName,
      surname: this.surname,
      emailAddresses: this.emailAddresses,
    });
  }

  async updateProfileImage(profileImage) {
    const { data } = await axios.put(
      config.api_url + "settings/profile-image",
      {
        profileImage,
      }
    );
    return data.data.profileImage;
  }

  setCurrentAdminEntity(entityId) {
    return axios.put(config.api_url + "user/manage-entity", {
      entity: entityId,
    });
  }

  isWatchingTeeTime(teeTime) {
    if (typeof teeTime.watchers === "undefined") {
      return false;
    }

    const watching = teeTime.watchers.find(
      (watcher) => String(watcher._id) === String(this._id)
    );
    return typeof watching !== "undefined";
  }

  hasClaimedTeeTime(teeTime) {
    return (
      typeof teeTime.claimedBy !== "undefined" &&
      teeTime.claimedBy._id === this._id
    );
  }

  hasClaimedTransaction(transaction) {
    return (
      typeof transaction.claimedBy !== "undefined" &&
      transaction.claimedBy._id === this._id
    );
  }

  hasClaimedEntity(entity) {
    return (
      typeof entity.claimedBy !== "undefined" &&
      entity.claimedBy._id === this._id
    );
  }

  get profileImageUrl() {
    if (typeof this.profileImage !== "undefined") {
      return config.content_url + "profiles/" + this.profileImage;
    }

    return profileImagePlaceholder;
  }

  static profileImageUrlForUser(user) {
    if (typeof user.newProfileImage !== "undefined") {
      return user.newProfileImage;
    }

    if (typeof user.profileImage !== "undefined") {
      return config.content_url + "profiles/" + user.profileImage;
    }

    return profileImagePlaceholder;
  }

  isMashieAdmin() {
    return this.has("MASHIE_ADMIN");
  }

  static claimTransaction(userId, transactionId) {
    return axios.post(
      config.api_url +
        "users/" +
        userId +
        "/transactions/" +
        transactionId +
        "/claim"
    );
  }
}

export default User;
