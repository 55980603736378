import axios from "axios";
import logoPlaceholder from "../assets/images/camera-placeholder.png";
const config = require("../config");

class Entity {
    constructor(properties) {
        this.branding = {};
        Object.assign(this, properties);
    }

    static async show(id) {
        const { data } = await axios.get(config.api_url + "entities/" + id);
        return new Entity(data.data);
    }

    static getByRegistrationToken(token) {
        return axios
            .get(config.api_url + "entities/registration/" + token)
            .then((response) => {
                return response;
            })
            .catch((error) => {
                throw error;
            });
    }

    static async index(params = {}) {
        const { data } = await axios.get(config.api_url + "entities", {
            params,
        });
        return {
            entities: data.data.map((entity) => new Entity(entity)),
            total: data.total,
        };
    }

    static async list(params = {}) {
        const { data } = await axios.get(config.api_url + "entities/list", {
            params,
        });
        return data.data.map((entity) => new Entity(entity));
    }

    async store() {
        const { data } = await axios.post(config.api_url + "entities", this);
        return new Entity(data.data);
    }

    async update() {
        const { data } = await axios.put(
            config.api_url + "entities/" + this._id,
            this
        );
        return new Entity(data.data);
    }

    async updateLogo(logo) {
        const response = await axios.put(
            config.api_url + "entities/" + this._id + "/logo",
            {
                logo,
            }
        );

        return response.data.data;
    }

    delete() {
        return axios.delete(config.api_url + "entities/" + this._id);
    }

    claim() {
        return axios.post(config.api_url + "entities/" + this._id + "/claim");
    }

    unclaim() {
        return axios.post(config.api_url + "entities/" + this._id + "/unclaim");
    }

    async storeNote(note) {
        const response = await axios.post(
            config.api_url + "entities/" + this._id + "/notes",
            {
                note,
            }
        );

        return response.data.data;
    }

    hasLogo() {
        return typeof this.logo !== "undefined";
    }

    get registrationLink() {
        return config.app_url + "auth/register/" + this.registrationToken;
    }

    get logoUrl() {
        if (typeof this.newLogo !== "undefined") {
            return this.newLogo;
        }

        if (typeof this.logo !== "undefined") {
            return config.content_url + "logos/" + this.logo;
        }

        return logoPlaceholder;
    }
}

export default Entity;
