import React, { Component } from "react";
import {
    Input,
    Form,
    Checkbox,
    Button,
    Icon,
    Segment,
} from "semantic-ui-react";
import User from "../../models/User";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import UserStoreActions from "../../store/actions/user";
import logo from "../../assets/images/mashie-shield-with-text.png";
import styles from "./login.module.scss";

class Login extends Component {
    constructor(props) {
        super(props);

        this.state = {
            email: "",
            password: "",
            remember: true,
            loading: false,
            errors: {},
            validationErrors: [],
        };
    }

    errorsFor(field) {
        if (typeof this.state.validationErrors[field] !== "undefined") {
            return (
                <span className="has-error">
                    {" "}
                    {this.state.validationErrors[field][0]}{" "}
                </span>
            );
        }
    }

    gatherInput(event) {
        this.setState({ [event.target.name]: event.target.value });
    }

    gatherCheckbox(event) {
        const set = {};
        set[event.target.previousSibling.name] =
            !event.target.previousSibling.checked;
        this.setState(set);
    }

    handleValidationErrors(error) {
        if (error.response.status === 422) {
            this.setState({ validationErrors: error.response.data.errors });
        }
    }

    attemptLogin() {
        this.setState({ loading: true });
        User.login(this.state.email, this.state.password)
            .then((response) => {
                this.props.dispatch(UserStoreActions.store(response.data.data));
                this.props.history.push("/members");
            })
            .catch((error) => {
                this.handleValidationErrors(error);
                this.setState({ loading: false });
            });
    }

    render() {
        return (
            <>
                <div className="container-fluid px-0 full-height">
                    <div className="row no-gutters full-height">
                        <div className="d-none d-sm-block col-sm-6 col-md-8">
                            <div className={styles.fullImage}></div>
                        </div>
                        <div className="col-12 col-sm-6 col-md-4">
                            <div className="light-background perfect-center">
                                <div className={"content " + styles.loginBox}>
                                    <img
                                        className={styles.logo}
                                        alt=""
                                        src={logo}
                                    />
                                    <div className="text-center">
                                        {this.errorsFor("authentication")}
                                    </div>
                                    <Form>
                                        <Form.Field>
                                            {this.errorsFor("email")}
                                            <Input
                                                inverted
                                                icon="at"
                                                size="huge"
                                                iconPosition="left"
                                                name="email"
                                                placeholder="Email"
                                                onChange={this.gatherInput.bind(
                                                    this
                                                )}
                                            />
                                        </Form.Field>
                                        <Form.Field>
                                            <Input
                                                inverted
                                                type="password"
                                                size="huge"
                                                icon="shield alternate"
                                                name="password"
                                                iconPosition="left"
                                                placeholder="Password"
                                                onChange={this.gatherInput.bind(
                                                    this
                                                )}
                                            />
                                        </Form.Field>
                                        <Form.Field className="text-center">
                                            <Checkbox
                                                toggle
                                                name="remember"
                                                label="Remember me?"
                                                value="1"
                                                checked={this.state.remember}
                                                onChange={this.gatherCheckbox.bind(
                                                    this
                                                )}
                                            />
                                        </Form.Field>
                                        <Form.Field>
                                            <Button
                                                fluid
                                                primary
                                                size="huge"
                                                className="theme"
                                                loading={this.state.loading}
                                                onClick={this.attemptLogin.bind(
                                                    this
                                                )}
                                            >
                                                <Icon name="check" />
                                            </Button>
                                        </Form.Field>
                                        <Form.Field className="text-center">
                                            <Link to="/auth/reset-password">
                                                {" "}
                                                <p className="text-center">
                                                    Forgotten your password?
                                                </p>
                                            </Link>
                                        </Form.Field>
                                    </Form>
                                    <Segment basic />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </>
        );
    }
}

function mapStateToProps(state) {
    return { user: state.user };
}

export default connect(mapStateToProps)(Login);
