import React, { Component } from "react";
import { Dropdown } from "semantic-ui-react";

class UserInterestsSelect extends Component {
  constructor(props) {
    super(props);
    this.interests = [
      { key: 0, text: "Rugby", value: "Rugby" },
      { key: 1, text: "Football", value: "Football" },
      { key: 2, text: "Cricket", value: "Cricket" },
      { key: 3, text: "Horse Racing", value: "Horse Racing" },
      { key: 4, text: "Formula 1", value: "Formula 1" },
      { key: 5, text: "Shooting", value: "Shooting" },
      { key: 6, text: "Tennis", value: "Tennis" },
      { key: 7, text: "NFL", value: "NFL" },
      { key: 8, text: "Casinos", value: "Casinos" },
      { key: 9, text: "Sports Betting", value: "Sports Betting" },
      { key: 10, text: "Indoor Golf", value: "Indoor Golf" },
      { key: 11, text: "Business Networking", value: "Business Networking" },
      { key: 12, text: "Celebrity Dinners", value: "Celebrity Dinners" },
      {
        key: 13,
        text: "Majors & Ryder Cup Travel",
        value: "Majors & Ryder Cup Travel",
      },
      {
        key: 14,
        text: "Roll ups with other Members",
        value: "Roll ups with other Members",
      },
    ];
  }

  render() {
    return (
      <Dropdown
        placeholder="Interests (select as many as you like)"
        value={this.props.value}
        onChange={(_, { value }) => this.props.onChange(value)}
        fluid
        multiple
        selection
        options={this.interests}
      />
    );
  }
}

export default UserInterestsSelect;
