import React, { useEffect, useState } from "react";
import Report from "../../../models/Report";
import DataTable from "../dataTable";
import formatNumberAsPrice from "../../../utilities/formatNumberAsPrice";

export default () => {
    const [loading, setLoading] = useState(true);
    const [data, setData] = useState([]);
    useEffect(() => {
        const fetchData = async () => {
            const data = await Report.memberCredit();
            setData(data);
            setLoading(false);
        };
        fetchData();
    }, []);

    const headers = [
        { key: "active", text: "Active membership" },
        { key: "expired", text: "No active membership" },
        { key: "total", text: "Total" },
    ];

    const tableData = [
        {
            active: { value: `£ ${formatNumberAsPrice(data.active)}` },
            expired: { value: `£ ${formatNumberAsPrice(data.expired)}` },
            total: { value: `£ ${formatNumberAsPrice(data.total)}` },
        },
    ];

    return (
        <>
            <h4>Available credit</h4>
            <p>
                The total amount of outstanding credit available to members,
                arranged according to whether they have an active membership
            </p>
            <DataTable
                loading={loading}
                headers={headers}
                data={tableData}
                hideSearch
                hideResultsCount
            />
        </>
    );
};
