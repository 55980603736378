import React, {Component} from 'react';
import { connect } from 'react-redux';
import Header from '../../assets/components/header';
import styles from './index.module.scss';
import CenterLoader from '../../assets/components/CenterLoader';
import Piece from '../../models/Piece';
import { Button, Icon, Segment } from 'semantic-ui-react'
import moment from 'moment';
import { Link } from 'react-router-dom';
import profileImagePlaceholder from '../../assets/images/user-tie.svg'
const config = require('../../config');
const numeral = require('numeral');

class Search extends Component {
	constructor(props) {
		super(props)
		this.state = {
			data: null,
			loading: true,
			pieces: []
		};
	}

	componentDidMount() {
		const { term } = this.props.match.params;
		this.setState({term});
		Piece.search(term).then(response => {
			this.setState({pieces: response.data.data.matches, loading: false});
		}).catch(error => {
		});
	}

	profileImage(author) {
		if (typeof author.profile_image !== 'undefined') {
			return config.content_url + 'profiles/' + author.profile_image;
		}

		return profileImagePlaceholder;
	}

	body() {
		if (this.state.loading === true) {
			return <CenterLoader />;
		}

		if (this.state.pieces.length === 0) {
			return (
				<p className="text-center"> There's nothing here yet</p>
			);
		}

		return(
			<>{
				this.state.pieces.map((piece, index) => {
								return(
									<Segment vertical key={index}>
									<div className="row">
										<div className="col">
											<Link to={'/read/' + piece.slug }><h2 className={styles.title}>{ piece.title }</h2></Link>
											<p className={styles.blurb}>{piece.blurb}</p>
											<p className={styles.pieceDetails}>
												<span><Link to={'/@' + piece.author.username}><img className={styles.profileImage} src={ this.profileImage(piece.author) } />{piece.author.name}</Link></span>
												<span>Published on {moment(piece.published_at).format('ll')}</span>
												<span>{numeral(piece.reads).format('0a')} reads so far</span>
											</p>
										</div>
									</div>
									</Segment>
								);
							})
			}</>
		);
	}

	render() {
		return (
			<>
				<Header history={this.props.history}/>
				<div className="container with-header pad-top">
					<h1><i>"{decodeURI(this.state.term)}"</i></h1>
					{ this.body() }
				</div>
			</>
		)
	}
}

function mapStateToProps(state) {
	return { user: state.user };
}

export default connect(mapStateToProps)(Search);
