import React, { Component } from "react";
import { Segment, Checkbox, Button } from "semantic-ui-react";

class Roles extends Component {
  constructor(props) {
    super(props);

    this.state = {
      user: props.user,
    };
  }

  componentDidMount() {
    if ("selected" in this.props) {
      const selected = this.props.roles.filter((role) =>
        this.props.selected.includes(role.key)
      );
      const { user } = this.state;
      user.roles = selected.map((role) => ({ role }));
      this.setState({ user });
    }
  }

  amendRole(value, key) {
    const { user } = this.state;
    const role = this.props.roles.find((role) => role.key === key);

    if (value.checked === true) {
      user.roles.push({ role });
      for (const permission of role.permissions) {
        user.allPermissions.push(permission.permission);
      }

      return this.setState({ user });
    }

    user.roles = user.roles.filter((role) => role.role.key !== key);
    const removePermissions = [];
    for (const permission of role.permissions) {
      removePermissions.push(permission.permission);
    }
    for (const removePermission of removePermissions) {
      user.allPermissions = user.allPermissions.filter(
        (permission) => permission.key !== removePermission.key
      );
    }

    this.setState({ user });
  }

  render() {
    return (
      <>
        {this.props.roles.map((role, i) => {
          return (
            <Segment vertical key={i}>
              <div className="row" key={i}>
                <div className="col">
                  <Checkbox
                    toggle
                    name="primary"
                    label={role.name}
                    value={role.key}
                    checked={this.props.user.hasRole(role.key)}
                    onChange={(_, value) => this.amendRole(value, role.key)}
                  />
                  <p>{role.description}</p>
                </div>
              </div>
            </Segment>
          );
        })}
        <Segment vertical>
          <Button
            size="large"
            floated="right"
            className="theme"
            primary
            onClick={this.props.save}
          >
            Save
          </Button>
        </Segment>
      </>
    );
  }
}

export default Roles;
