import React, {Component} from 'react';
import { Link } from 'react-router-dom';
import styles from './menu.module.scss';
import UserStoreActions from '../../store/actions/user';
import { connect } from 'react-redux';
import User from '../../models/User';
import { Icon } from 'semantic-ui-react'

class Menu extends Component {

	logout() {
		User.logout()
			.then(response => {
				this.props.dispatch(UserStoreActions.logOutUser());
			})
			.catch(error => {
				this.props.history.push('/auth/login');
			});
	}

	render() {
		return (
			<nav className={styles.menu + (this.props.show ? '' : ' hidden')}>
				<ul>
					<Link to="/pieces/create">
						<li>
							<Icon name="pencil" />New Piece
						</li>
					</Link>
					<li>
						<Icon name="file text" />My Pieces
					</li>
					<li onClick={this.logout.bind(this)}>
						<Icon name="log out" />Log out
					</li>
				</ul>
			</nav>
		)
	}
}

function mapStateToProps(state) {
	return { user: state.user };
}

export default connect(mapStateToProps)(Menu);
