import React, { useEffect, useState } from "react";
import AppUsage from "../../../models/AppUsage";
import DataTable from "../dataTable";
import format from "date-fns/format";

export default () => {
    const [loading, setLoading] = useState(true);
    const [table, setTable] = useState({
        page: 1,
        term: "",
        sort: "createdAt",
        order: "desc",
        limit: 25,
    });
    const [totalRecords, setTotalRecords] = useState(0);
    const [data, setData] = useState([]);
    useEffect(() => {
        getData(table);
    }, []);

    const getData = async (params) => {
        const data = await AppUsage.index(params);
        setData(data.items);
        setTotalRecords(data.total);
        setTable(params);
        setLoading(false);
    };

    const headers = [
        { key: "displayKey", text: "Page" },
        { key: "date", text: "Date" },
    ];
    const tableData = data.map((item) => {
        return {
            displayKey: { value: item.displayKey },
            date: {
                value: format(new Date(item.createdAt), "dd/MM/yyyy, kk:mm"),
            },
        };
    });

    return (
        <>
            <h4>List</h4>
            <p>
                An list of the different pages on the app members have visited
                recently
            </p>
            <DataTable
                loading={loading}
                headers={headers}
                data={tableData}
                totalRecords={totalRecords}
                page={table.page}
                onChange={getData}
                searchTerm={table.term}
                sort={table.sort}
                order={table.order}
                limit={table.limit}
                sortOptions={[
                    { text: "Date", key: "createdAt" },
                    { text: "Page", key: "key" },
                ]}
                pagination
            />
        </>
    );
};
