import React, {Component} from 'react';
import { Icon } from 'semantic-ui-react';

class Partial404 extends Component {
	render() {
		return (
			<div className="container text-center">
				<Icon name="question" size="huge" />
				<h2>404</h2>
				<h3>We couldn't find that. Please try again.</h3>
			</div>
		);
	}
}



export default Partial404;
