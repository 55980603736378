const register = (state = {}, action) => {
	switch (action.type) {
		case 'SET_ERROR':
			return Object.assign({}, state, { errors: action.payload });
		default:
		return state
	}
}

export default register;
