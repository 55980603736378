import axios from "axios";
import logoPlaceholder from "../assets/images/camera-placeholder.png";
const config = require("../config");

class EventSeries {
    constructor(properties) {
        Object.assign(this, properties);
    }

    static async index(params) {
        const { data } = await axios.get(config.api_url + "event-series/", {
            params,
        });
        return {
            series: data.data.map((event) => new EventSeries(event)),
            total: data.total,
        };
    }

    static async list() {
        const { data } = await axios.get(config.api_url + "event-series/list");
        return data.data.map((event) => new EventSeries(event));
    }

    static async getById(id) {
        const { data } = await axios.get(config.api_url + "event-series/" + id);
        return new EventSeries(data.data);
    }

    async store() {
        const { data } = await axios.post(config.api_url + "event-series", {
            name: this.name,
            isHidden: this.isHidden,
            image: this.image,
        });
        return new EventSeries(data.data);
    }

    async update() {
        const { data } = await axios.put(
            config.api_url + "event-series/" + this._id,
            { name: this.name, isHidden: this.isHidden }
        );
        return new EventSeries(data.data);
    }

    async updateImage(image) {
        const { data } = await axios.put(
            config.api_url + "event-series/" + this._id + "/image",
            { image }
        );
        return data.data;
    }

    delete() {
        return axios.delete(config.api_url + "event-series/" + this._id);
    }

    get imageUrl() {
        if (typeof this.newImage !== "undefined") {
            return this.newImage;
        }

        if (typeof this.image !== "undefined") {
            return config.content_url + "event-series/images/" + this.image;
        }

        return logoPlaceholder;
    }
}

export default EventSeries;
