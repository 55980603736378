import React, {Component} from 'react';
import styles from './ImageCropper.module.scss';
import { Button } from 'semantic-ui-react'
import CropperJs from 'cropperjs';
import 'cropperjs/dist/cropper.css'

class ImageCropper extends Component {
	constructor(props) {
		super(props);
		this.state = {
			showCropper: false
		};
	}

	componentDidMount() {
		document.getElementById('logo-upload').onchange = (evt) => {
	    var tgt = evt.target || window.event.srcElement,
	        files = tgt.files;

	    // FileReader support
	    if (FileReader && files && files.length) {
	        var fr = new FileReader();
					fr.readAsDataURL(files[0]);
	        fr.onload = () => {
						const preview = document.getElementById('preview-image');
	          preview.src = fr.result;
						const cropper = new CropperJs(preview, {
							aspectRatio: 1,
							autoCropArea: 1,
							zoomable: true,
							scalable: true,
							viewMode: 0,
							cropBoxResizable: true
						 });
						this.setState({cropper, showCropper: true});
	        }
	    }

	    // Not supported
	    else {
				this.notification('error', 'Failed to save club');
	    }
		}
	}

	selectImage() {
		const image = document.getElementById('logo-upload');
		image.click();
	}

	setImage() {
		const base64ImageString = this.state.cropper.getCroppedCanvas().toDataURL('image/png');
	 	this.state.cropper.destroy();
		this.setState({showCropper: false});
		this.props.onChange(base64ImageString);
	}

	showCropperButton() {
		if (this.state.showCropper === false) {
			return <Button primary className="theme" onClick={this.selectImage.bind(this)}>Choose image</Button>
		}

		return <Button icon="check" className="theme" primary onClick={(this.setImage.bind(this))}>Save</Button>
	}

	render() {
		const hidePreview = this.state.showCropper ? '' : 'hidden';

		return (
			<>
				<input id="logo-upload" className="hidden" type="file" />
				<div className={styles.imagePreviewContainer}>
					<img id="preview-image" className={styles.imagePreview + ' ' + hidePreview}/>
				</div>
				{ this.showCropperButton() }
			</>
		)
	}
}


export default ImageCropper;
