import React from 'react';
import { connect } from 'react-redux';
import UserStoreActions from '../store/actions/user';
import { Dimmer, Progress } from 'semantic-ui-react';
import logo from '../assets/images/mashie-shield-with-text.png';
import { Redirect } from 'react-router-dom';
import User from '../models/User';
import CenterLoader from '../assets/components/CenterLoader';

export default (RouteComponent, reverse = false) => {
	class RedirectIfAuthenticated extends React.Component {

		constructor(props) {
			super(props);
			this.state = {
				rejectedByServer: false
			}
		}

		check() {
			User.authCheck()
				.then(response => {
					this.props.dispatch(UserStoreActions.store(response.data.data));
				})
				.catch(error => {
					this.setState({rejectedByServer: true});
				});
		}

		show() {
			//If the user is authenticated, throw them to the dashboard
			if (this.props.user.isAuthenticated() === true) {
				return (<Redirect to="/members" />);
			}

			//If the user is not authenticated, but they have not yet been rejected by the server, go and check
			if (this.state.rejectedByServer === false && this.props.user.isAuthenticated() === false) {
				this.check();
				return <CenterLoader />
			}

			// They have been rejected by the server, show the router
			return (<RouteComponent {...this.props}/>);
		}

		render() {
			return (
				<div className="full">
					{ this.show() }
				</div>
			);
		}
	}

	function mapStateToProps(state) {
		return { user: state.user };
	}

	return connect(mapStateToProps)(RedirectIfAuthenticated);
}
