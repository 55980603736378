import axios from "axios";
import Member from "./Member";
const config = require("../config");

class EventBooking {
    constructor(properties) {
        Object.assign(this, properties);
    }

    static async index(params) {
        const { data } = await axios.get(config.api_url + "event-bookings", {
            params,
        });
        return {
            bookings: data.data.map((booking) => new EventBooking(booking)),
            total: data.total,
        };
    }

    static async getById(id) {
        const { data } = await axios.get(
            config.api_url + "event-bookings/" + id
        );
        const booking = new EventBooking(data.data);
        booking.user = new Member(booking.user);
        return booking;
    }

    static async userIndex(id, params) {
        const { data } = await axios.get(
            config.api_url + "users/" + id + "/event-bookings",
            { params }
        );
        return {
            bookings: data.data.map((booking) => new EventBooking(booking)),
            total: data.total,
        };
    }

    store() {
        return axios
            .post(config.api_url + "event-bookings", this)
            .then((response) => {
                return response;
            })
            .catch((error) => {
                throw error;
            });
    }

    update() {
        return axios
            .put(config.api_url + "event-bookings/" + this._id, {
                players: this.players,
                playerDetails: this.playerDetails,
            })
            .then((response) => {
                return response;
            })
            .catch((error) => {
                throw error;
            });
    }

    delete() {
        return axios.delete(config.api_url + "event-bookings/" + this._id);
    }

    get formattedPrice() {
        return parseFloat(this.event.price).toFixed(2).replace(/\.00$/, "");
    }

    async storeNote(note) {
        const response = await axios.post(
            config.api_url + "event-bookings/" + this._id + "/notes",
            { note }
        );
        return response.data.data;
    }
}

export default EventBooking;
