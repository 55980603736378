import UserStoreActions from '../store/actions/user';
const axios = require('axios');

export default (store) => {
	axios.interceptors.response.use(response => {
		return response;
	}, error => {
			if (error.response.data === "Authentication required") {
				store.dispatch(UserStoreActions.logOutUser());
			}

			return Promise.reject(error);
		});
}
