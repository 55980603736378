import React, {Component} from 'react';
import { Icon } from 'semantic-ui-react';

class Partial500 extends Component {
	render() {
		return (
			<div className="container text-center">
				<Icon name="exclamation" size="huge" />
				<h2>500</h2>
				<h3>Something went wrong. Please try again.</h3>
			</div>
		);
	}
}



export default Partial500;
