import React, { Component } from "react";
import { Input, Segment, Select, Checkbox, Button } from "semantic-ui-react";
import { connect } from "react-redux";

class EmailAddresses extends Component {
  constructor(props) {
    super(props);

    this.emailTypes = [
      {
        key: 0,
        value: "Personal",
        text: "Personal",
      },
      {
        key: 1,
        value: "Work",
        text: "Work",
      },
      {
        key: 2,
        value: "Alternate",
        text: "Alternate",
      },
    ];
  }

  errorsFor(field) {
    if (this.props.validation !== null) {
      if (typeof this.props.validation[field] !== "undefined") {
        return (
          <span className="has-error"> {this.props.validation[field][0]} </span>
        );
      }
    }
  }

  add() {
    const { user } = this.props;
    const primary = user.emailAddresses.length === 0;
    user.emailAddresses.push({ email: "", emailType: "Work", primary });
    this.props.update(user);
  }

  remove(index) {
    const { user } = this.props;
    user.emailAddresses.splice(index, 1);
    this.props.update(user);
  }

  onChangeEmail(value, index) {
    const { user } = this.props;
    user.emailAddresses[index].email = value;
    this.props.update(user);
  }

  onChangePrimary(value, index) {
    const { user } = this.props;
    for (const email of user.emailAddresses) {
      email.primary = false;
    }
    user.emailAddresses[index].primary = value.checked;
    this.props.update(user);
  }

  onChangeType(result, index) {
    const { user } = this.props;
    user.emailAddresses[index].emailType = result.value;
    this.props.update(user);
  }

  list() {
    return this.props.user.emailAddresses.map((email, i) => {
      return (
        <div className="row mt-3" key={i}>
          <div className="col-sm-12 col-md-7 mb-2">
            <div className="row">
              <div className="col-6">
                <label className="with-input">Email</label>
                <Input
                  fluid
                  name={i}
                  placeholder="example@example.com"
                  value={email.email}
                  onChange={(event) =>
                    this.onChangeEmail(event.target.value, i)
                  }
                />
              </div>
              <div className="col-6">
                <label className="with-input">Email type</label>
                <Select
                  fluid
                  value={email.emailType}
                  options={this.emailTypes}
                  name={i}
                  onChange={(_, result) => this.onChangeType(result, i)}
                  placeholder="Email type"
                />
              </div>
            </div>
          </div>
          <div className="col-xs-12 col-md-5 text-right d-flex align-items-center justify-content-end">
            <Checkbox
              toggle
              label="Primary"
              value={i}
              checked={email.primary}
              onChange={(_, value) => this.onChangePrimary(value, i)}
            />
            <Button
              primary
              className="red ml-3"
              value={i}
              onClick={() => this.remove(i)}
            >
              Remove
            </Button>
          </div>
        </div>
      );
    });
  }

  render() {
    return (
      <>
        <Segment vertical>
          {this.errorsFor("emailAddresses")}
          <label className="with-input">Email addresses</label>
          {this.list()}
          <div className="row">
            <div className="col-12">
              <Button
                primary
                className="theme mt-3"
                onClick={this.add.bind(this)}
              >
                Add email
              </Button>
            </div>
          </div>
        </Segment>
      </>
    );
  }
}

function mapStateToProps(state) {
  return {
    validation: state.validation,
  };
}

export default connect(mapStateToProps)(EmailAddresses);
