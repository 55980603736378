import axios from "axios";
const config = require("../config");

class PurchaseTransaction {
    constructor(properties) {
        Object.assign(this, properties);
    }

    static async index(params) {
        const { data } = await axios.get(
            config.api_url + "purchase-transactions",
            {
                params,
            }
        );
        return {
            transactions: data.data,
            total: data.total,
        };
    }
}

export default PurchaseTransaction;
