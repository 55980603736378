import React, { useEffect, useState } from "react";
import Report from "../../../models/Report";
import DataTable from "../dataTable";

export default () => {
    const [loading, setLoading] = useState(true);
    const [data, setData] = useState([]);
    useEffect(() => {
        const fetchData = async () => {
            const data = await Report.appUsageOverview();
            setData(data);
            setLoading(false);
        };
        fetchData();
    }, []);

    const headers = [
        { key: "displayKey", text: "Page" },
        { key: "count", text: "Occurances" },
    ];
    const tableData = data.map((item) => {
        return {
            displayKey: { value: item.displayKey },
            count: { value: item.count },
        };
    });

    return (
        <>
            <h4>Overview</h4>
            <p>
                An overview of how many times members have visited different
                pages on the app
            </p>
            <DataTable
                loading={loading}
                headers={headers}
                data={tableData}
                hideSearch
                hideResultsCount
            />
        </>
    );
};
