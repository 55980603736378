class ValidationAction {
	static create(data) {
		return { type: "SET_VALIDATION", payload: data };
	}

	static remove(data) {
		return { type: "CLEAR_VALIDATION" };
	}
}

export default ValidationAction;
