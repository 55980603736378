const axios = require("axios");
const config = require("../config");

class AppVariable {
  constructor(properties) {
    Object.assign(this, properties);
  }

  static async getByIdOrKey(idOrKey) {
    const response = await axios.get(
      config.api_url + "app-variables/" + idOrKey
    );
    return response.data.data;
  }

  static getAppVariables() {
    return axios
      .get(config.api_url + "app-variables")
      .then((response) => {
        return response;
      })
      .catch((error) => {
        throw error;
      });
  }

  store() {
    return axios
      .post(config.api_url + "app-variables", {
        name: this.name,
        isEnabled: this.isEnabled,
        key: this.key,
        val: this.val,
        data: this.data,
      })
      .then((response) => {
        return response;
      })
      .catch((error) => {
        throw error;
      });
  }

  update() {
    return axios
      .put(config.api_url + "app-variables/" + this._id, {
        name: this.name,
        isEnabled: this.isEnabled,
        key: this.key,
        val: this.val,
        data: this.data,
      })
      .then((response) => {
        return response;
      })
      .catch((error) => {
        throw error;
      });
  }
}

export default AppVariable;
