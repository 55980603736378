import React, { Component } from "react";
import { Input, Segment } from "semantic-ui-react";
import { connect } from "react-redux";

class Details extends Component {
  errorsFor(field) {
    if (this.props.validation !== null) {
      if (typeof this.props.validation[field] !== "undefined") {
        return (
          <span className="has-error"> {this.props.validation[field][0]} </span>
        );
      }
    }
  }

  gatherInput(event) {
    this.props.user[event.target.name] = event.target.value;
    this.props.update(this.props.user);
  }

  render() {
    return (
      <>
        <Segment vertical>
          {this.errorsFor("firstName")}
          <label className="with-input">First name</label>
          <Input
            fluid
            name="firstName"
            value={this.props.user.firstName || ""}
            onChange={this.gatherInput.bind(this)}
          />
        </Segment>
        <Segment vertical>
          {this.errorsFor("surname")}
          <label className="with-input">Surname</label>
          <Input
            fluid
            name="surname"
            value={this.props.user.surname || ""}
            onChange={this.gatherInput.bind(this)}
          />
        </Segment>
      </>
    );
  }
}

function mapStateToProps(state) {
  return {
    validation: state.validation,
  };
}

export default connect(mapStateToProps)(Details);
