import React from "react";
import styles from "./center-loader.module.scss";

class Loader extends React.Component {
    render() {
        return (
            <div className={styles.loader + " " + this.props.className}></div>
        );
    }
}

export default Loader;
