import React, { Component } from "react";
import styles from "./style.module.scss";
import { Input, Segment, Button } from "semantic-ui-react";
import { connect } from "react-redux";
import NotificationActions from "../../../store/actions/notification";
import GolfClub from "../../../models/GolfClub";

class Images extends Component {
  addNewImage(event) {
    const { target } = event;
    const { club } = this.props;
    const fileReader = new FileReader();
    if (target.files.length > 0) {
      fileReader.readAsDataURL(target.files[0]);
      fileReader.onload = async () => {
        const image = await club.storeImage(fileReader.result);
        club.images.push(image);
        this.props.dispatch(
          NotificationActions.create({ type: "success", text: "Image saved" })
        );
        this.props.update(club);
      };
    }
  }

  async removeImage(index) {
    const { club } = this.props;
    const image = club.images[index];
    club.images.splice(index, 1);
    await club.deleteImage(image._id);
    this.props.dispatch(
      NotificationActions.create({ type: "success", text: "Image deleted" })
    );
    this.props.update(club);
  }

  onChangeImageTitle(index, value) {
    const { club } = this.props;
    club.images[index].title = value;
    this.props.update(club);
  }

  async saveImageTitle(index) {
    const { club } = this.props;
    const image = club.images[index];
    await club.updateImage(image._id, image.title);
    this.props.dispatch(
      NotificationActions.create({ type: "success", text: "Image saved" })
    );
  }

  addImage() {
    return (
      <div className="row">
        <div className="col">
          <Segment vertical>
            <input
              type="file"
              id="newImageFileInput"
              className="hidden"
              onChange={this.addNewImage.bind(this)}
            />
            <Button
              primary
              className="theme center"
              onClick={() =>
                document.getElementById("newImageFileInput").click()
              }
            >
              Add image
            </Button>
          </Segment>
        </div>
      </div>
    );
  }

  listImages() {
    if (this.props.club.images.length === 0) {
      return (
        <div className="col">
          <p className="text-center">This club has no images yet</p>
        </div>
      );
    }

    return this.props.club.images.map((image, index) => {
      return (
        <div className="col-6 mt-3" key={index}>
          <div className={styles.clubImagePreviewContainer}>
            <img
              className={styles.clubImagePreview}
              id={"imagePreview-" + index}
              src={GolfClub.clubImage(image)}
            />
            <Button
              primary
              onClick={() => this.removeImage(index)}
              name={index}
              className={styles.clubImagePreviewDeleteButton + " red"}
            >
              Remove
            </Button>
          </div>
          <Input
            fluid
            placeholder="Title"
            value={image.title || ""}
            className="mt-2"
            name={index}
            onChange={({ target }) =>
              this.onChangeImageTitle(index, target.value)
            }
            action={
              <Button primary onClick={() => this.saveImageTitle(index)}>
                Save
              </Button>
            }
          />
        </div>
      );
    });
  }

  render() {
    return (
      <>
        <div className="row">{this.listImages()}</div>
        {this.addImage()}
        <div className="row">
          <div className="col">
            <Segment vertical>
              <Button
                size="large"
                floated="right"
                className="theme"
                primary
                onClick={this.props.save}
                disabled={this.props.saving}
                loading={this.props.saving}
              >
                Save
              </Button>
            </Segment>
          </div>
        </div>
      </>
    );
  }
}

function mapStateToProps(state) {
  return {
    validation: state.validation,
  };
}

export default connect(mapStateToProps)(Images);
