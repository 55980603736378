import React, { useEffect, useRef } from "react";
import { Wrapper } from "@googlemaps/react-wrapper";

function MapInternal({ history, golfClubs }) {
    const ref = useRef();
    const center = { lat: 51.9060106, lng: -2.0796443 };
    const zoom = 4;

    useEffect(() => {
        const map = new window.google.maps.Map(ref.current, {
            center,
            zoom,
        });

        const markerImage = {
            url: "/mashie-pin.png",
            size: new window.google.maps.Size(23, 40),
            origin: new window.google.maps.Point(0, 0),
            anchor: new window.google.maps.Point(11, 40),
            scaledSize: new window.google.maps.Size(23, 40),
        };

        const bounds = new window.google.maps.LatLngBounds();

        for (const golfClub of golfClubs) {
            if (golfClub.location?.length !== 2) {
                continue;
            }

            const pin = new window.google.maps.LatLng(
                golfClub.location[1],
                golfClub.location[0]
            );
            bounds.extend(pin);
            const marker = new window.google.maps.Marker({
                position: pin,
                map: map,
                title: golfClub.name,
                icon: markerImage,
            });

            marker.addListener("click", () => {
                history.push("/golf-clubs/" + golfClub._id);
            });
        }

        map.fitBounds(bounds);
    });

    return <div ref={ref} id="map" style={{ width: "100%", height: 400 }} />;
}

function Map({ history, golfClubs }) {
    return (
        <Wrapper apiKey="AIzaSyAs_ascZBHN1UOpg9BvRc0jkqqlIkIPgF8">
            <MapInternal history={history} golfClubs={golfClubs} />
        </Wrapper>
    );
}

export default Map;
