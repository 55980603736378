import React, {Component} from 'react';
import { Input, Form, Checkbox, Button, Icon, Segment } from 'semantic-ui-react'
import User from '../../models/User';
import Header from '../../assets/components/header';
import CenterLoader from '../../assets/components/CenterLoader';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import logo from '../../assets/images/mashie-shield-with-text.png';
import styles from './login.module.scss';

class RequestPasswordReset extends Component {
	constructor(props) {
		super(props);

		this.state = {
			errors: [],
			requested: false,
			loading: false,
			email: ''
		};
	}

	errorsFor(field) {
		if (typeof this.state.errors[field] !== 'undefined') {
			return <span className="has-error"> { this.state.errors[field][0] } </span>
		}
	}

	request() {
		this.setState({loading: true})
		User.requestPasswordReset(this.state.email)
			.then(response => {
				this.setState({ errors: [], requested: true, loading: false})
			}).catch(error => {
				this.setState({loading: false, requested: false, errors: error.response.data.errors});
			});
	}

	gatherInput(event) {
		this.setState({ [event.target.name]: event.target.value });
	}

	body() {
		if (this.state.errors.length > 0) {
			return (
					<>
						<h2 className="text-center"><Icon name="exclamation circle" color="red"/>That didn't work.</h2>
						<p>Something went wrong.</p>
					</>
			);
		}

		if (this.state.requested === true) {
			return (
					<>
						<div className="text-center"><Icon name="check circle" size="huge" color="green"/></div>
						<h2 className="text-center">PASSWORD RESET REQUESTED</h2>
						<p>If you email address is found, we will send you a link to reset your password.</p>
					</>
			);
		}

		return (
				<>
					<div className="text-center"><Icon name="shield" size="huge" color="grey"/></div>
					<h2 className="text-center">RESET PASSWORD</h2>
					<p>Please enter your email address so we can send you a link to reset your password.</p>
					<Form>
					<Form.Field>
						{ this.errorsFor('email') }
						<Input inverted icon="at" size="huge" iconPosition="left" name="email" placeholder="Email" onChange={this.gatherInput.bind(this)} />
					</Form.Field>
					<Form.Field>
					<Button fluid primary size="huge" className="theme" loading={this.state.loading} onClick={this.request.bind(this)}>
						<Icon name="check" />
					</Button>
					</Form.Field>
					</Form>
				</>
		);
	}

	render() {
		return (
			<>
				<div className="container-fluid px-0 full-height">
					<div className="row no-gutters full-height">
						<div className="d-none d-sm-block col-sm-6 col-md-8">
							<div className={styles.fullImage}>
							</div>
						</div>
						<div className="col-12 col-sm-6 col-md-4">
							<div className="light-background perfect-center">
								<div className={'content ' + styles.loginBox}>
									<img className={styles.logo} alt="" src={logo} />
									{ this.body() }
								</div>
							</div>
						</div>
					</div>
				</div>
			</>
		);
	}
}

function mapStateToProps(state) {
	return { user: state.user };
}


export default connect(mapStateToProps)(RequestPasswordReset);
