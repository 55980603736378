import React, { useState, useEffect } from "react";
import { Button, Form, TextArea, Segment } from "semantic-ui-react";
import logo from "../../../assets/images/mashie-shield-with-text.png";
import LoadingPlaceholder from "../../../assets/components/LoadingPlaceholder";
import wait from "../../../utilities/wait";
import StarSelect from "../../../assets/components/StarSelect";
import ClubReview from "../../../models/ClubReview";
import BookingReview from "../../../models/BookingReview";
import styles from "./style.module.scss";

const ReviewQuestionnaire = ({ match }) => {
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(false);
    const [submitError, setSubmitError] = useState(false);
    const [submitSuccess, setSubmitSuccess] = useState(false);
    const [clubReview, setClubReview] = useState(null);
    const [clubReviewQuestions, setClubReviewQuestions] = useState([]);
    const [reviewText, setReviewText] = useState("");
    const [bookingReview, setBookingReview] = useState(null);
    const [bookingProcessStars, setBookingProcessStars] = useState(5);
    const [bookingProcessFeedback, setBookingProcessFeedback] = useState("");
    const [eventStars, setEventStars] = useState(5);
    const [eventFeedback, setEventFeedback] = useState("");

    useEffect(() => {
        const fetchData = async () => {
            try {
                const clubReview = await ClubReview.publicShow(
                    match.params.reference
                );

                if (typeof clubReview.submittedAt !== "undefined") {
                    setSubmitSuccess(true);
                }

                // Need to design the payload for submitting responses
                setClubReviewQuestions(
                    clubReview.questions.map((question) => {
                        const item = {
                            key: question.key,
                        };
                        if (question.questionType === "RATING") {
                            item.answer = 5;
                        }
                        if (question.questionType === "TEXT") {
                            item.answer = "";
                        }
                        return item;
                    })
                );

                setClubReview(clubReview);
            } catch (_) {
                setError(true);
            }

            try {
                const bookingReview = await BookingReview.publicShow(
                    match.params.reference
                );

                setBookingReview(bookingReview);
            } catch (e) {
                setBookingReview(null);
            }

            setLoading(false);
        };
        fetchData();
    }, []);

    const submit = async () => {
        setLoading(true);
        await wait(2000);

        if (bookingReview !== null) {
            try {
                const payload = {
                    bookingProcessStars,
                    bookingProcessFeedback,
                    eventStars,
                    eventFeedback,
                };
                await bookingReview.publicSubmit(payload);
            } catch (e) {
                setLoading(false);
                return setSubmitError(true);
            }
        }
        try {
            const clubPayload = {
                questions: clubReviewQuestions,
                reviewText,
            };
            await clubReview.publicSubmit(clubPayload);
            setSubmitSuccess(true);
        } catch (e) {
            setSubmitError(true);
        }
        setLoading(false);
    };

    const setQuestionAnswer = (key, value) => {
        const question = clubReviewQuestions.find(
            (question) => question.key === key
        );
        question.answer = value;
        setClubReviewQuestions(clubReviewQuestions);
    };

    const content = () => {
        if (error === true) {
            return (
                <>
                    <h2>We're having trouble finding your rating invitation</h2>
                    <p>Your invitation link may have expired.</p>
                </>
            );
        }

        if (submitError === true) {
            return (
                <>
                    <h2>There was a problem submitting your rating</h2>
                    <p>Please refresh the page and try again.</p>
                </>
            );
        }

        if (submitSuccess === true) {
            return (
                <>
                    <h2>Thank you for submitting your rating!</h2>
                    <p>
                        Your rating will help us improve the MASHIE experience
                        for everyone.
                    </p>
                </>
            );
        }

        if (loading === true) {
            return <LoadingPlaceholder fluid />;
        }

        const showQuestion = (questionAnswer) => {
            const question = clubReview.questions.find(
                ({ key }) => key === questionAnswer.key
            );
            return (
                <Segment vertical key={question._id}>
                    <h4>{question.description}</h4>
                    {question.questionType === "RATING" && (
                        <StarSelect
                            defaultScore={questionAnswer.answer}
                            onChange={(score) =>
                                setQuestionAnswer(questionAnswer.key, score)
                            }
                        />
                    )}
                    {question.questionType === "TEXT" && (
                        <TextArea
                            value={questionAnswer.answer}
                            onChange={({ target }) =>
                                setQuestionAnswer(
                                    questionAnswer.key,
                                    target.value
                                )
                            }
                        />
                    )}
                </Segment>
            );
        };

        return (
            <Form>
                <h3>
                    Leave a rating about your experience at{" "}
                    {clubReview.club.name}
                </h3>
                <p>
                    Thank you for taking the time to leave a rating. Your
                    feedback will help us to improve the MASHIE offering and
                    give valuable insights to other members.
                </p>

                {clubReviewQuestions.map(showQuestion)}

                <Segment vertical>
                    <h4>Club rating</h4>
                    <p>
                        How was your overall experience at{" "}
                        {clubReview.club.name}?
                    </p>
                    <TextArea
                        placeholder="This question is optional"
                        name="clubFeedback"
                        value={reviewText}
                        onChange={({ target }) => setReviewText(target.value)}
                    />
                </Segment>
                <p>
                    All club-related ratings will be available for members to
                    view within the app. MASHIE also reseves the right to use
                    them anonymously in marketing material.
                </p>
                {bookingReview !== null && bookingReviewContent()}
                <Segment vertical>
                    <div>
                        <Button
                            primary
                            className="theme mt-4"
                            onClick={() => submit()}
                            disabled={false}
                        >
                            Submit rating
                        </Button>
                    </div>
                </Segment>
            </Form>
        );
    };

    const bookingReviewContent = () => {
        return (
            <>
                <Segment className="mt-5" vertical>
                    <h4>Booking process rating</h4>
                    <p>How would you rate the MASHIE booking process?</p>
                    <StarSelect
                        defaultScore={bookingProcessStars}
                        onChange={(score) => setBookingProcessStars(score)}
                    />
                </Segment>
                <Segment vertical>
                    <h4>Booking process feedback</h4>
                    <p>
                        How was your experience with the MASHIE booking process?
                    </p>
                    <TextArea
                        placeholder="This question is optional"
                        name="bookingProcessFeedback"
                        value={bookingProcessFeedback}
                        onChange={({ target }) =>
                            setBookingProcessFeedback(target.value)
                        }
                    />
                </Segment>
                <p>
                    Any feedback relating to the booking process is for internal
                    use only and will never be shared publically.
                </p>
                {bookingReview.eventBooking && (
                    <>
                        <Segment className="mt-5" vertical>
                            <h4>Event rating</h4>
                            <p>
                                How would you rate{" "}
                                {bookingReview.eventBooking.event.name} overall?
                            </p>
                            <StarSelect
                                defaultScore={eventStars}
                                onChange={(score) => setEventStars(score)}
                            />
                        </Segment>
                        <Segment vertical>
                            <h4>Event feedback</h4>
                            <p>
                                How was your experience at{" "}
                                {bookingReview.eventBooking.event.name}? Were
                                you satisfied with the event?
                            </p>
                            <TextArea
                                placeholder="This question is optional"
                                name="eventFeedback"
                                value={eventFeedback}
                                onChange={({ target }) =>
                                    setEventFeedback(target.value)
                                }
                            />
                        </Segment>
                        <p>
                            Any feedback relating to events is for internal use
                            only and will never be shared publically.
                        </p>
                    </>
                )}
            </>
        );
    };

    return (
        <div className="container-fluid px-0 full-height">
            <div className="row no-gutters full-height">
                <div className="d-none d-sm-block col-sm-6 col-md-8">
                    <div className={styles.fullImage}></div>
                </div>
                <div className="col-12 col-sm-6 col-md-4 p-5">
                    <img className={styles.logo} alt="" src={logo} />
                    {content()}
                </div>
            </div>
        </div>
    );
};

export default ReviewQuestionnaire;
