import React from 'react';
import { connect } from 'react-redux';
import UserStoreActions from '../store/actions/user';
import { Dimmer, Progress } from 'semantic-ui-react';
import logo from '../assets/images/mashie-shield-with-text.png';
import { Redirect } from 'react-router-dom';
import User from '../models/User';
import CenterLoader from '../assets/components/CenterLoader';

export default (RouteComponent, allowUnauthenticated = false) => {
	class RequireAuth extends React.Component {

		constructor(props) {
			super(props);
			this.state = {
				rejectedByServer: false
			}
		}

		check() {
			User.authCheck()
				.then(response => {
					this.props.dispatch(UserStoreActions.store(response.data.data));
				})
				.catch(error => {
					this.setState({rejectedByServer: true});
				});
		}

		show() {
			//If the user is authenticated, carry on
			if (this.props.user.isAuthenticated() === true) {
				return (<RouteComponent {...this.props}/>);
			}

			// If the user is not authenticated, and they have been rejected by the server, throw them out
			if (this.props.user.isAuthenticated() === false && this.state.rejectedByServer === true) {
				if (allowUnauthenticated === true) {
					return (<RouteComponent {...this.props}/>);
				}

				return (<Redirect to="/auth/login" />);
			}

			// Go and check with the server to see if they have a valid auth cookie
			this.check();

			return <CenterLoader />
		}

		render() {
			return (
				<div className="full">
					{ this.show() }
				</div>
			);
		}
	}

	function mapStateToProps(state) {
		return { user: state.user };
	}

	return connect(mapStateToProps)(RequireAuth);
}
