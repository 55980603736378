const axios = require("axios");
const config = require("../config");

class Report {
    static async memberCredit(id) {
        const { data } = await axios.get(
            config.api_url + "reports/members/credit"
        );
        return data.data;
    }

    static async appUsageOverview(id) {
        const { data } = await axios.get(
            config.api_url + "reports/app-usage/overview"
        );
        return data.data;
    }
}

export default Report;
