import React, { Component } from "react";
import { Input, Segment, Button, Select } from "semantic-ui-react";
import { connect } from "react-redux";

class Contacts extends Component {
  constructor(props) {
    super(props);

    this.state = {
      editing: null,
    };

    this.positions = [
      {
        key: 0,
        value: "Director",
        text: "Director",
      },
      {
        key: 1,
        value: "General Manager",
        text: "General Manager",
      },
      {
        key: 2,
        value: "Sales",
        text: "Sales",
      },
      {
        key: 3,
        value: "Pro",
        text: "Pro",
      },
      {
        key: 4,
        value: "Staff",
        text: "Staff",
      },
      {
        key: 5,
        value: "Booking",
        text: "Booking",
      },
    ];
  }

  gatherInput(property, value) {
    const { club } = this.props;
    club.contacts[this.state.editing][property] = value;
    this.props.update(club);
  }

  addContact() {
    const { club } = this.props;
    const contact = {
      position: "Staff",
      name: "",
      email: "",
      phone: "",
    };
    club.contacts.push(contact);
    this.setState({ editing: club.contacts.length - 1 });
    this.props.update(club);
  }

  save() {
    this.setState({ editing: null });
    this.props.save();
  }

  deleteContact(index) {
    const { club } = this.props;
    club.contacts.splice(index, 1);
    this.props.update(club);
  }

  onChangePosition(value) {
    const { club } = this.props;
    club.contacts[this.state.editing].position = value;
    this.props.update(club);
  }

  addContactButton() {
    if (this.state.editing === null) {
      return (
        <Segment vertical>
          <Button
            primary
            className="theme"
            onClick={this.addContact.bind(this)}
          >
            Add contact
          </Button>
        </Segment>
      );
    }
  }

  listContacts() {
    if (this.state.editing !== null) {
      return;
    }

    if (this.props.club.contacts.length === 0) {
      return <p className="text-center">This club has no contacts yet.</p>;
    }

    return this.props.club.contacts.map((contact, index) => {
      return (
        <Segment vertical key={index}>
          <div className="row">
            <div className="col-10">
              <h4 className="mt-2">{contact.name}</h4>
              <p className="m-0">
                <b>Position:</b> {contact.position}
              </p>
              <p className="m-0">
                <b>Email:</b> {contact.email}
              </p>
              <p className="m-0">
                <b>Phone:</b> {contact.phone}
              </p>
            </div>
            <div className="col-2 d-flex align-items-center justify-content-end">
              <Button
                primary
                className="theme"
                onClick={() => this.setState({ editing: index })}
              >
                Edit
              </Button>
              <Button
                primary
                className="red"
                onClick={() => this.deleteContact(index)}
              >
                Delete
              </Button>
            </div>
          </div>
        </Segment>
      );
    });
  }

  contactEditor() {
    if (this.state.editing === null) {
      return;
    }

    const contact = this.props.club.contacts[this.state.editing];

    return (
      <>
        <Segment vertical>
          <label className="with-input">Position</label>
          <Select
            fluid
            value={contact.position}
            options={this.positions}
            onChange={(_, { value }) => this.onChangePosition(value)}
            placeholder="Position"
          />
        </Segment>
        <Segment vertical>
          <label className="with-input">Name</label>
          <Input
            fluid
            name="name"
            value={contact.name}
            onChange={({ target }) => this.gatherInput("name", target.value)}
            placeholder="Name"
          />
        </Segment>
        <Segment vertical>
          <label className="with-input">Email</label>
          <Input
            fluid
            name="email"
            value={contact.email}
            onChange={({ target }) => this.gatherInput("email", target.value)}
            placeholder="Email"
          />
        </Segment>
        <Segment vertical>
          <label className="with-input">Phone</label>
          <Input
            fluid
            name="phone"
            value={contact.phone}
            onChange={({ target }) => this.gatherInput("phone", target.value)}
            placeholder="Phone"
          />
        </Segment>
        <div className="row">
          <div className="col">
            <Segment vertical>
              <Button
                size="large"
                floated="right"
                className="theme"
                primary
                onClick={this.save.bind(this)}
              >
                Save
              </Button>
            </Segment>
          </div>
        </div>
      </>
    );
  }

  render() {
    return (
      <>
        {this.listContacts()}
        {this.contactEditor()}
        {this.addContactButton()}
      </>
    );
  }
}

function mapStateToProps(state) {
  return {
    user: state.user,
    notification: state.notification,
    validation: state.validation,
  };
}

export default connect(mapStateToProps)(Contacts);
