import axios from "axios";
const config = require("../config");

class EventReserve {
    constructor(properties) {
        Object.assign(this, properties);
    }

    static async index(params) {
        const { data } = await axios.get(config.api_url + "event-reserves", {
            params,
        });
        return {
            reserves: data.data.map((reserve) => new EventReserve(reserve)),
            total: data.total,
        };
    }

    delete() {
        return axios.delete(config.api_url + "event-reserves/" + this._id);
    }
}

export default EventReserve;
