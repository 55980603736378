import React, { Component } from "react";
import { Button, Segment, Form, TextArea } from "semantic-ui-react";
import moment from "moment";

class Notes extends Component {
    constructor(props) {
        super(props);
        this.state = {
            newNote: "",
        };
    }

    onChangeNewNote(value) {
        this.setState({ newNote: value });
    }

    async save() {
        const { entity } = this.props;
        const note = await this.props.entity.storeNote(this.state.newNote);
        this.props.entity.internalNotes.push(note);
        this.setState({ newNote: "" });
        this.props.update(entity);
    }

    list() {
        return this.props.entity.internalNotes.map((note, index) => {
            return (
                <Segment vertical key={index}>
                    <span>
                        <b>{note.createdBy.name}</b>
                    </span>
                    <span className="ml-3">
                        {moment(note.createdAt).fromNow()}
                    </span>
                    <p className="mt-3">{note.note}</p>
                </Segment>
            );
        });
    }

    render() {
        return (
            <>
                <Form className="mb-3">
                    <TextArea
                        value={this.state.newNote}
                        placeholder={"Add a new note"}
                        onChange={({ target }) =>
                            this.onChangeNewNote(target.value)
                        }
                    />
                    <Button
                        primary
                        className="theme mt-3"
                        onClick={() => this.save()}
                        disabled={this.state.newNote.length === 0}
                    >
                        Add
                    </Button>
                </Form>
                {this.list()}
            </>
        );
    }
}

export default Notes;
