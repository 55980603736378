import React, { Component } from "react";
import Header from "../../assets/components/header";
import Footer from "../../assets/components/footer";
import MemberCredit from "../../assets/components/reports/MemberCredit";
import AppUsageOverview from "../../assets/components/reports/AppUsageOverview";
import AppUsageList from "../../assets/components/reports/AppUsageList";

class Reports extends Component {
    body() {
        return (
            <div className="container">
                <div className="row">
                    <div className="col">
                        <h2>Reports</h2>
                    </div>
                </div>
                <div className="row mt-3">
                    <div className="col">
                        <h3>Members</h3>
                    </div>
                </div>
                <div className="row mt-3">
                    <div className="col">
                        <MemberCredit />
                    </div>
                </div>
                <hr />
                <div className="row mt-3">
                    <div className="col">
                        <h3>App Usage</h3>
                    </div>
                </div>
                <div className="row mt-3">
                    <div className="col-3">
                        <AppUsageOverview />
                    </div>
                    <div className="col-9">
                        <AppUsageList />
                    </div>
                </div>
            </div>
        );
    }

    render() {
        return (
            <>
                <div className="d-flex main">
                    <Header history={this.props.history} />
                    {this.body()}
                </div>
                <Footer />
            </>
        );
    }
}

export default Reports;
