import React from "react";
import { Segment } from "semantic-ui-react";
import GolfClubSearchSelect from "../GolfClubSearchSelect";
import EntitySearchSelect from "../EntitySearchSelect";
import EventSearchSelect from "../EventSearchSelect";
import OfferSearchSelect from "../OfferSearchSelect";
import Offer from "../../../models/Offer";
import Event from "../../../models/Event";
import GolfClub from "../../../models/GolfClub";
import Entity from "../../../models/Entity";

export default ({ onChange }) => {
    const onSelectClub = async (value) => {
        const golfClubs = await GolfClub.list({ include: value });
        return onChange(
            golfClubs[0].images[0]?.image
                ? "clubs/images/" + golfClubs[0].images[0]?.image
                : null
        );
    };

    const onSelectEntity = async (value) => {
        const entities = await Entity.list({ include: value });
        return onChange(entities[0].logo ? "logos/" + entities[0].logo : null);
    };

    const onSelectOffer = async (value) => {
        const offers = await Offer.list({ include: value });
        return onChange(offers[0].logo ? "logos/" + offers[0].logo : null);
    };

    const onSelectEvent = async (value) => {
        const events = await Event.list({ include: value });
        return onChange(
            events[0].image ? "events/images/" + events[0].image : null
        );
    };

    return (
        <>
            <h5>Select a header image for the email</h5>
            <Segment vertical>
                <label className="with-input">Golf Club</label>
                <GolfClubSearchSelect
                    fluid
                    onChange={(value) => onSelectClub(value)}
                />
            </Segment>
            <Segment vertical>
                <label className="with-input">Entity</label>
                <EntitySearchSelect
                    fluid
                    onChange={(value) => onSelectEntity(value)}
                />
            </Segment>
            <Segment vertical>
                <label className="with-input">Partner Offer</label>
                <OfferSearchSelect
                    fluid
                    onChange={(value) => onSelectOffer(value)}
                />
            </Segment>
            <Segment vertical>
                <label className="with-input">Event</label>
                <EventSearchSelect
                    fluid
                    onChange={(value) => onSelectEvent(value)}
                />
            </Segment>
        </>
    );
};
