const validation = (state = {}, action) => {
	switch (action.type) {
		case 'SET_VALIDATION':
			return action.payload;
		case 'CLEAR_VALIDATION':
			return null;
		default:
		return state;
	}
}

export default validation;
