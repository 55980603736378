import React, {Component} from 'react';
import { Input, Form, Checkbox, Button, Icon, Segment } from 'semantic-ui-react'
import User from '../../models/User';
import Header from '../../assets/components/header';
import CenterLoader from '../../assets/components/CenterLoader';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import logo from '../../assets/images/mashie-shield-with-text.png';
import styles from './login.module.scss';

class Verify extends Component {
	constructor(props) {
		super(props);

		this.state = {
			errors: [],
			verified: false,
			loading: true
		};
	}

	verify() {
		User.verfyEmail(this.props.match.params.token)
			.then(response => {
				this.setState({ errors: [], verified: true, loading: false})
			}).catch(error => {
				this.setState({loading: false, verified: false, errors: error.response.data.errors});
			});
	}

	componentDidMount() {
		this.verify();
	}

	body() {
		if (this.state.loading === true) {
			return <CenterLoader />
		}

		if (this.state.errors.length > 0) {
			return (
				<div className="perfect-center">
					<div className="content skinny">
						<h1 className="text-center display-font"><Icon name="exclamation circle" color="red"/>That didn't work.</h1>
						<p>We were not able to verify your email address. Please try signing in instead. If your email address still requires verification, you will receive another link immediately.</p>
						<Button className="green" floated="right">Sign in</Button>
					</div>
				</div>
			);
		}

		if (this.state.verified === true) {
			return (
				<div className="perfect-center">
					<div className="content skinny">
						<h1 className="text-center display-font"><Icon name="check circle" color="green"/>Thank you!</h1>
						<p>Your email address has been verified. Go ahead and sign in.</p>
						<Link to="/auth/login"><Button className="green">Sign in</Button></Link>
					</div>
				</div>
			);
		}
	}


	render() {
		return (
			<>
				<Header history={this.props.history}/>
				<div className="container full-height">
					<div className="grid full-height">
						{ this.body() }
					</div>
				</div>
			</>
		);
	}
}

function mapStateToProps(state) {
	return { user: state.user };
}


export default connect(mapStateToProps)(Verify);
