import User from "../../models/User";

class UserAction {
  static store(data) {
    const user = new User(data);
    return { type: "STORE", payload: user };
  }

  static logOutUser() {
    const user = new User();
    return { type: "LOGOUT", payload: user };
  }
}

export default UserAction;
