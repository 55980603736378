import React, {Component} from 'react';
import Partial404 from './partials/404';
import Header from '../../assets/components/header';

class Error404 extends Component {
	render() {
		return (
			<div className="d-flex main">
				<Header history={this.props.history}/>
				<Partial404 />
			</div>
		);
	}
}



export default Error404;
