import axios from "axios";
const config = require("../config");

class CreditAllocation {
    constructor(properties) {
        Object.assign(this, properties);

        if (typeof this.createdAt === "string") {
            this.createdAt = new Date(this.createdAt);
        }
    }

    static async index(params) {
        const { data } = await axios.get(
            config.api_url + "credit-allocations",
            {
                params,
            }
        );
        return {
            allocations: data.data,
            total: data.total,
        };
    }
}

export default CreditAllocation;
