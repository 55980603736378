import React, {Component} from 'react';
import styles from './HtmlEditor.module.scss';
import Quill from 'quill';
import 'quill/dist/quill.snow.css';

class HtmlEditor extends Component {
	constructor(props) {
		super(props)
		this.tempId = Math.random().toString(20).substr(2, 6);
	}

	componentDidMount() {
		const quill = new Quill('#description_editor_' + this.tempId, {
			modules: {
				toolbar: [
					[{ 'header': 1 }, { 'header': 2 }],
					['bold', 'italic', 'underline', 'link'],
					[{ 'align': [] }],
					[{ 'list': 'bullet' }],
                    [{ 'color': [] }]
				]
			},
			placeholder: this.props.placeholder ? this.props.placeholder : 'Event description...',
			theme: 'snow'
		});

		quill.root.innerHTML = this.props.content || '';
		quill.on('text-change', () => {
			const contents = quill.root.innerHTML;
			this.props.onChange(contents);
		});
	}

	render() {
		return (
			<div className={styles.descriptionEditor}>
				<div id={'description_editor_' + this.tempId}></div>
			</div>
		);
	}
}

export default HtmlEditor;
