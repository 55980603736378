const subjectGolfClub = (state = {}, action) => {
	switch (action.type) {
		case 'SET_GOLF_CLUB':
			return action.payload;
		default:
		return state;
	}
}

export default subjectGolfClub;
