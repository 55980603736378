import React, {Component} from 'react';
import styles from './footer.module.scss';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';

class UserDetails extends Component {
	constructor(props) {
		super(props);
		this.state = {
			menu: false
		};
	}

	render() {
		return (
			<footer className={styles.footer}>
				<div className="container-fluid">
					<div className="row no-gutters">
						<div className="col text-center">
						      &copy; Mashie 2020
						</div>
					</div>
				</div>
			</footer>
		)
	}
}

function mapStateToProps(state) {
	return { user: state.user };
}

export default connect(mapStateToProps)(UserDetails);
