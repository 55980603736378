import axios from "axios";
const config = require("../config");

class AppUsage {
    constructor(properties) {
        this.branding = {};
        Object.assign(this, properties);
    }

    static async index(params = {}) {
        const { data } = await axios.get(config.api_url + "app-usage", {
            params,
        });
        return {
            items: data.data.map((entity) => new AppUsage(entity)),
            total: data.total,
        };
    }

    static async list(params = {}) {
        const { data } = await axios.get(config.api_url + "app-usage/list", {
            params,
        });
        return data.data.map((entity) => new AppUsage(entity));
    }
}

export default AppUsage;
