import React, {Component} from 'react';
import Header from '../../assets/components/header';
import Footer from '../../assets/components/footer';

class Terms extends Component {
	body() {
		return (
			<div className="container">
			    <h1>MASHIE Mobile Application - the 'MASHIE App'</h1>
			    <h2>Licence Terms and Conditions - Preliminary Scheme</h2>
			    <h3>OUR TERMS</h3>
			    <ol>
			        <li>
			            <strong>THESE TERMS</strong>
			            <ol>
			                <li><strong>What these terms cover</strong>. These are the terms and conditions upon which we licence the MASHIE App to you. By downloading the MASHIE App you agree to these terms, if you don't agree to these terms, please delete the MASHIE App.</li>
			                <li><strong>Why you should read them</strong>. Please read these terms carefully before you register for a MASHIE App account. By ticking the "I have read and agree to the terms and conditions of the MASHIE App” checkbox you agree to these terms. If you do not agree to these terms, do not request an account and delete the MASHIE App. These terms tell you who we are, what we are licensing you to use, how you may use and not use the MASHIE App, how you and we may change or end the licence agreement, what to do if there is a problem and other important information. If you think that there is a mistake in these terms please contact us by emailing our customer service team via our website: <a href="http://www.MASHIE.co.uk">www.MASHIE.co.uk</a>.</li>
			                <li><strong>"MASHIE App" </strong>means the MASHIE Mobile Application and integral features within.</li>
			            </ol>
			        </li>
			        <li>
			            <strong>INFORMATION ABOUT US AND HOW TO CONTACT US</strong>
			            <ol>
			                <li><strong>Who we are</strong>. We are Coinbridge Ltd (trading under the MASHIE brand) a company registered in England and Wales. Our company registration number is 576777 and our registered office is at 7 The Hayloft, Far Peak, Northleach, Gloucestershire, United Kingdom, GL54 3AP. Our registered VAT number is GB 225 7567 89.</li>
			                <li><strong>How to contact us</strong>. You can contact us by emailing our customer service team via our website: <a href="http://www.mashiegolf.co.uk">www.mashiegolf.co.uk</a>.</li>
			                <li><strong>How we may contact you</strong>. If we have to contact you we will generally do so by email, or by another media selected by you when creating or updating your account on the MASHIE App, such as email or push notifications.</li>
			                <li><strong>"Writing" includes emails and push notifications</strong>. When we use the words “writing” or “written” in these terms, this includes emails and push notifications.</li>
			            </ol>
			        </li>
			        <li>
			            <strong>OUR LICENCE AGREEMENT WITH YOU</strong>
			            <ol>
			                <li><strong>How we will accept your MASHIE App account application</strong>. Our acceptance of your account will take place when you accept the terms and conditions of the MASHIE App and confirm your account registration, at which point a licence agreement will come into existence between you and us.</li>
			                <li><strong>If we cannot accept your account registration</strong>. If we are unable to accept your account registration, we will inform you in writing.</li>
			                <li><strong>What the MASHIE App licence provides</strong>. It grants you the licence to use the MASHIE mobile application, the latest version of the mobile application software, the data supplied with the software and any updates or supplements to it.</li>
			                <li><strong>Apple App Store's terms also apply. </strong>Use of the MASHIE App is also subject to compliance with Apple App Store's terms and conditions. In the event of any conflict between Apple App Store's terms and the terms of this licence agreement, Apple App Store's terms and conditions will take precedence.</li>
			                <li><strong>Google App Store's terms also apply. </strong>Use of the MASHIE App is also subject to compliance with Google App Store's terms and conditions. In the event of any conflict between Google App Store's terms and the terms of this licence agreement, Google App Store's terms and conditions will take precedence.</li>
			                <li><strong>Age. </strong>You must be 16 or over to accept these terms and download the MASHIE App.</li>
			                <li><strong>Transfer. </strong>The Licence to use the MASHIE App is granted to you personally. You may not otherwise transfer the MASHIE App to someone else. If you sell any device upon which the MASHIE App is installed, you must remove the MASHIE App from the device before you sell/otherwise dispose of it.</li>
			                <li><strong>Licence restrictions. </strong>You agree that you will:
			                    <ol style={{listStyleType: 'lower-alpha'}}>
			                <li>not rent, lease, sub-licence, loan, provide, or otherwise make available, the MASHIE App in any form, in whole or in part to any person without our prior written consent;</li>
			                <li>not copy the MASHIE App except as part of the normal use of the MASHIE App or where it is necessary for the purpose of back-up or operational security;</li>
			                <li>not translate, merge, adapt, vary, alter or modify, the whole or any part of the MASHIE App, nor permit the MASHIE App or any part of it to be combined with, or become incorporated in, any other programs, except as necessary to use the MASHIE App on devices as permitted in these terms;</li>
			                <li>not disassemble, de-compile, reverse engineer or create derivative works based on the whole or any part of the MASHIE App nor attempt to do any such things, except to the extent that (by virtue of sections 50B and 296A of the Copyright, Designs and Patents Act 1988) such actions cannot be prohibited because they are necessary to decompile the MASHIE App to obtain the information necessary to create an independent program that can be operated with the MASHIE App or with another program ("<strong>Permitted Objective"</strong>), and provided that the information obtained by you during such activities:
			                    <ol style={{listStyleType: 'lower-roman'}}>
			                <li>is not disclosed or communicated without our prior written consent to any third party to whom it is not necessary to disclose or communicate it in order to achieve the Permitted Objective; and</li>
			                <li>is not used to create any software that is substantially similar in its expression to the MASHIE App;</li>
			                <li>is kept secure; and</li>
			                <li>is used only for the Permitted Objective;</li>
			                <li>comply with all applicable technology control or export laws and regulations that apply to the technology used or supported by the MASHIE App.</li>
			            </ol>
			        </li>
			    </ol>
			    </li>
			    <li><strong>Acceptable use restrictions. </strong>You must:
			        <ol style={{listStyleType: 'lower-alpha'}}>
			    <li>not use the MASHIE App in any unlawful manner, for any unlawful purpose, or in any manner inconsistent with these terms, or act fraudulently or maliciously, for example, by hacking into or inserting malicious code, such as viruses, or harmful data, into the MASHIE App or any operating system;</li>
			    <li>not infringe our intellectual property rights or those of any third party in relation to your use of the MASHIE App;</li>
			    <li>not transmit any material that is defamatory, offensive or otherwise objectionable in relation to your use of the MASHIE App;</li>
			    <li>not use the MASHIE App in a way that could damage, disable, overburden, impair or compromise our systems or security or interfere with use by others of the MASHIE App; and</li>
			    <li>not collect or harvest any information or data from the MASHIE App or attempt to decipher any transmissions to or from the servers supporting the MASHIE App.</li>
			    </ol>
			    </li>
			    <li><strong>Use of the MASHIE App.</strong> In return for you agreeing to comply with these terms you may:
			        <ol style={{listStyleType: 'lower-alpha'}}>
			    <li>download or stream a copy of the MASHIE App onto as many devices as necessary and view, use and display the MASHIE App for your personal purposes only.</li>
			    <li>make a single copy of the MASHIE App for back-up purposes.</li>
			    <li>receive and use any free supplementary software code or update the MASHIE App incorporating ‘patches’ and corrections of errors as we may provide to you.</li>
			    </ol>
			    </li>
			    </ol>
			    </li>
			    <li>
			        <strong>OPERATING SYSTEM REQUIREMENTS</strong>
			        <ol>
			            <li><strong>Minimum operating system requirements</strong>. The MASHIE App requires a smartphone device with a minimum of 1GB of memory and iOS 10 or greater or Android 7 and greater operating system. The MASHIE App will only function on a smartphone device with these capabilities as a minimum and will not operate on a tablet.</li>
			            <li>The MASHIE App will work with the current version of the operating system (as it may be updated from time to time) and match the description of it provided to you when you bought it.</li>
			        </ol>
			    </li>
			    <li>
			        <strong>THE MASHIE APP</strong>
			        <ol>
			            <li><strong>Linked Company</strong>. When you register for an account via the MASHIE App you will be pre-assigned a “Linked Company” from the list of available MASHIE programme companies.</li>
			            <li><strong>Marketing preferences.</strong>your Linked Company and MASHIE can also provide you with details of offers and updates on related services. We may also provide links to other websites and/or mobile applications you can access by selecting these from the MASHIE App. Please see the terms relating to your use of these third party links in clause 5.5 '(Other websites/mobile applications/apps').</li>
			            <li><strong>Other websites/mobile applications/apps.</strong> We are not responsible for other websites and/or mobile applications/apps you link to via the MASHIE App (except www.mashiegolf.co.uk). Third party websites and/or mobile applications/apps are not under our control, and we are not responsible for and have not checked and approved their content or their privacy policies (if any). You will need to make your own independent judgement about whether to use any such third party websites and/or mobile applications/apps, including whether to buy any products or services offered by them.</li>
			            <li>
			                <strong>What will happen if you do not give required information to us or if the information you supply is inaccurate/incomplete</strong>. We will need certain information from you so that we can provide you with a MASHIE App account. The information we will need is: your name, Name, Gender, Email, Phone Number, Address, password, Birthday, MASHIE Handicap, CONGU Handicap, Home Golf Clubs, Clothing sizes, Preferred Fit, Interests. You will be asked to update this information when you create your MASHIE App account. If you give us incomplete or incorrect information, we may not be able to register your account. The MASHIE App can also:
			                <ol>
			                    <li>show events; and/or</li>
			                    <li>book tee-times. </li>
			                </ol>
			            </li>
			        </ol>
			    </li>
			    <li>
			        <strong>OUR RIGHTS TO MAKE CHANGES TO THE MASHIE APP</strong>
			        <ol>
			            <li><strong>We may make changes to the MASHIE App:</strong>
			                <ol style={{listStyleType: 'lower-alpha'}}>
			            <li>to reflect changes in relevant laws and regulatory requirements;</li>
			            <li>to implement minor technical adjustments and improvements; and/or</li>
			            <li>to deal with additional features we may introduce.</li>
			        </ol>
			    </li>
			    <li><strong>Automatic changes</strong>. From time to time we may automatically update the MASHIE App including but not limited to the reasons outlined above.</li>
			    <li><strong>Updates.</strong>On other occasions we may ask you to update the MASHIE App. If you choose not to install such updates or if you opt out of automatic updates you may not be able to continue using the MASHIE App.</li>
			    <li><strong>Acceptance of changes.</strong>If you do not accept the notified changes you will not be permitted to continue to use the MASHIE App.</li>
			    <li><strong>We reserve the right to take such action as we consider necessary in relation to the MASHIE App, including but not limited to revising the contents and withdrawing the MASHIE App in its entirety without notice.</strong></li>
			    </ol>
			    </li>
			    <li>
			        <strong>IF THERE IS A PROBLEM WITH THE SERVICE</strong>
			        <ol>
			            <li><strong>Issues with the MASHIE App</strong>. If you think the MASHIE App is faulty or misdescribed or wish to contact us for any other reason please email our customer service team via our website: <a href="http://www.mashiegolf.co.uk">http://www.mashiegolf.co.uk</a>.</li>
			        </ol>
			    </li>
			    <li>
			        <strong>HOW TO END THE AGREEMENT WITH US</strong>
			        <ol>
			            <li><strong>You can always end your agreement with us</strong>. To end your agreement with us, email <a href="mailto:data.protection@mashiegolf.co.uk">data.protection@mashiegolf.co.uk</a> and request that your MASHIE App account be deleted. You can remove the MASHIE App from your smartphone device at any time but your account details will only be deleted (and any push/email notifications cancelled) by emailing us at <a href="mailto:data.protection@mashiegolf.co.uk">data.protection@mashiegolf.co.uk</a> to request your account’s deletion.</li>
			        </ol>
			    </li>
			    <li>
			        <strong>OUR RIGHTS TO END THE AGREEMENT</strong>
			        <ol>
			            <li><strong>We may end the agreement.</strong>We may end your rights to use the MASHIE App at any time by contacting you if you have broken these terms in a serious way. If what you have done can be put right we will give you a reasonable opportunity to do so.</li>
			            <li>If we end your rights to use the MASHIE App, you must:
			                <ol style={{listStyleType: 'lower-alpha'}}>
			            <li>stop all activities authorised by these terms, including your use of the MASHIE App; and</li>
			            <li>delete or remove the MASHIE App from all your devices.
			                <br />We may remotely access your device/s and remove the MASHIE App from them, delete your account information and cease providing you with access to the MASHIE App.
			            </li>
			        </ol>
			    </li>
			    </ol>
			    </li>
			    <li>
			        <strong>OUR RESPONSIBILITY FOR LOSS OR DAMAGE SUFFERED BY YOU</strong>
			        <ol>
			            <li><strong>We are responsible to you for foreseeable loss and damage caused by us</strong>. If we fail to comply with these terms, we are responsible for loss or damage you suffer that is a foreseeable result of our breaking this agreement or our failing to use reasonable care and skill, but we are not responsible for any loss or damage that is not foreseeable. Loss or damage is foreseeable if either it is obvious that it will happen or if, at the time the agreement was made, both we and you knew it might happen.</li>
			            <li><strong>We do not exclude or limit in any way our liability to you where it would be unlawful to do so</strong>. This includes liability for death or personal injury caused by our negligence or the negligence of our employees, agents or subcontractors; for fraud or fraudulent misrepresentation.</li>
			            <li><strong>We are not liable for business losses</strong>. The MASHIE App is for domestic and private use. If you use the MASHIE App for any commercial, business or re-sale purpose we will have no liability to you for any loss of profit, loss of business, business interruption, or loss of business opportunity.</li>
			            <li><strong>When we are liable for damage to your property.</strong>If defective digital content that we have supplied damages a device or digital content belonging to you, we will either repair the damage or pay you compensation. However, we will not be liable for damage that you could have avoided by following our advice to apply an update offered to you free of charge or for damage that was caused by you failing to correctly follow installation instructions or to have in place the minimum system requirements advised by us.</li>
			            <li><strong>Limitations to the MASHIE App</strong>. The MASHIE App is provided for general information and entertainment purposes only. It does not offer advice upon which you should rely. You must obtain professional or specialist advice before taking, or refraining from, any action on the basis of information obtained from the MASHIE App. Although we make reasonable efforts to update the information provided by the MASHIE App, we make no representations, warranties or guarantees, whether express or implied, that such information is accurate, complete or up to date.</li>
			            <li><strong>Please back-up content and data used with the MASHIE App</strong>. We recommend that you back up any content and data used in connection with the MASHIE App, to protect yourself in case of problems with the MASHIE App.</li>
			            <li><strong>Check that the MASHIE App is suitable for you</strong>. The MASHIE App has not been developed to meet your individual requirements. Please check that the facilities and functions of the MASHIE App (as described on the App store site) meet your requirements.</li>
			            <li><strong>We are not responsible for events outside our control.</strong>If our provision of the services or support for the MASHIE App is delayed by an event outside our control then we will contact you as soon as possible to let you know and we will take steps to minimise the effect of the delay. Provided we do this we will not be liable for delays caused by the event but if there is a risk of substantial delay you may contact us to end your agreement with us.</li>
			            <li><strong>No tee-time booking guarantee.</strong>The submission of a request a tee-time is no guarantee one will be secured. We are not responsible for reminding you of your tee-time bookings or cancelled/declined bookings.</li>
			        </ol>
			    </li>
			    <li>
			        <strong>HOW WE MAY USE YOUR PERSONAL INFORMATION</strong>
			        <ol>
			            <li><strong>How we will use your personal information</strong>. We will use the personal information you provide to us, detailed at Clause 5, in the ways we set out in our privacy policy on our website on this link <a href="https://wvw.mashiegolf.co.uk/privacy-policy" target="_blank">https://wvw.mashiegolf.co.uk/privacy-policy</a> and is also available on your App Store prior to downloading the App.</li>
			            <li><strong>Licensees accessing your personal information. </strong>By consenting to these terms, you consent to us passing your personal data to our licensees solely for the purpose of providing the MASHIE App. Our licensees are Coinbridge Limited and companies enrolled on the MASHIE programme. Only entit(iy/ies) you currently link with will be able to view your personal information submitted via the MASHIE App.</li>
			            <li><strong>Communications.</strong>In order to provide reminder notifications and/or marketing via the MASHIE App, we may contact you by push notifications and/or email.</li>
			            <li><strong>Internet transmissions.</strong>These communications are never completely private or secure and any message or information you send using the MASHIE App may be read or intercepted by others, even if there is a special notice that a particular transmission is encrypted.</li>
			            <li><strong>Technical data. </strong>We may collect technical data about your device. By using the MASHIE App you agree to us collecting and using technical information about the devices you use the MASHIE App on and related software, hardware and peripherals to improve our products and to provide any services to you.</li>
			            <li><strong>Location data. </strong>We may collect location data. Certain services including the operating system, will make use of location data sent from your devices. You can turn off this functionality at any time by turning off the location services settings for the MASHIE App on the device. If you use the MASHIE APP, you consent to us and our affiliates' and licensees' transmission, collection, retention, maintenance, processing and use of your location data and queries to provide and improve location-based and road traffic-based products and services. You may stop us collecting such data at any time by turning off the location services settings from the operating system.</li>
			            <li><strong>We will only give your personal information to third parties in addition to those detailed in the privacy policy where you allow us to do so, or the law either requires or allows us to do so</strong>.</li>
			        </ol>
			    </li>
			    <li>
			        <strong>OTHER IMPORTANT TERMS</strong>
			        <ol>
			            <li><strong>Intellectual property rights. </strong>All intellectual property rights in the MASHIE App belong to us or our licensors and the rights in the MASHIE App are licensed (not sold) to you. You have no intellectual property rights in, or to, the MASHIE App other than the right to use it in accordance with these terms.</li>
			            <li><strong>We may transfer this agreement to someone else</strong>. We may transfer our rights and obligations under these terms to another organisation. We will always tell you in writing if this happens and we will ensure that the transfer will not affect your rights under the contract.</li>
			            <li><strong>You need our consent to transfer your rights to someone else</strong>. You may only transfer your rights or your obligations under these terms to another person if we agree to this in writing.</li>
			            <li><strong>Nobody else has any rights under this contract</strong>. This contract is between you and us. No other person shall have any rights to enforce any of its terms. Neither of us will need to get the agreement of any other person in order to end the contract and we do not need your consent to make any changes to these terms.</li>
			            <li><strong>Rights of Third parties. </strong>This agreement does not give rise to any rights under the Contracts (Rights of Third parties) Act 1999 to enforce any term of this agreement.</li>
			            <li><strong>If a court finds part of this agreement illegal, the rest will continue in force</strong>. Each of the paragraphs of these terms operates separately. If any court or relevant authority decides that any of them are unlawful, the remaining paragraphs will remain in full force and effect.</li>
			            <li><strong>Even if we delay in enforcing this contract, we can still enforce it later</strong>. If we do not insist immediately that you do anything you are required to do under these terms, or if we delay in taking steps against you in respect of your breaking this agreement, that will not mean that you do not have to do those things and it will not prevent us taking steps against you at a later date. For example, if you infringe our intellectual property rights and we do not identify it immediately we can still identify it and progress the issue at a later date.</li>
			            <li><strong>Alternative dispute resolution </strong>Alternative dispute resolution is a process where an independent body considers the facts of a dispute and seeks to resolve it, without you having to go to court. If you are not happy with how we have handled any complaint, you may want to contact the alternative dispute resolution provider we use. You can submit a complaint to the Centre for Effective Dispute Resolution (<strong>CEDR</strong>) via their website at <a href="https://www.cedr.com/consumer/" target="_blank">https://www.cedr.com/consumer/</a>. CEDR will not charge you for making a complaint and if you are not satisfied with the outcome you can still bring legal proceedings. In addition, please note that disputes may be submitted for online resolution to the European Commission Online Dispute Resolution platform.</li>
			            <li><strong>Which laws apply to this contract and where you may bring legal proceedings</strong>. These terms are governed by English law and you can bring legal proceedings in respect of the MASHIE App in the English courts. If you live in Scotland you can bring legal proceedings in respect of the MASHIE in either the Scottish or the English courts. If you live in Northern Ireland you can bring legal proceedings in respect of the MASHIE App in either the Northern Irish or the English courts.</li>
			        </ol>
			    </li>
			    </ol>
			</div>
		);
	}

	render() {
		return (
			<>
				<div className="d-flex main">
					{ this.body() }
				</div>
				<Footer />
			</>
		);
	}
}



export default Terms;
